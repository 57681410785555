<template>
  <div>
    <ul class="nav nav-tabs" role="tablist">
      <li
        v-for="page in pages"
        :key="page.name"
        class="nav-item"
        :class="{active: page.name === activeTabName}"
      >
        <a class="nav-link" @click="changePage(page.name)">{{page.label}}</a>
      </li>
    </ul>
    <div class="hg-tab-content">
      <div
        v-for="page in pages"
        :key="page.name"
        class="hg-tab-pane"
        :class="{'is-shown': page.name === activeTabName}"
        :id="page.name"
      >
        <component :ref="`tabcomponent_${page.name}`" v-bind="tabProps" :is="page.component"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pages', 'initialPage', 'tabProps'], // pages = { label, name, component }
  data() {
    return {
      activeTabName: null,
    };
  },
  methods: {
    changePage(name) {
      this.activeTabName = name;
      const activeTabComponentRefName = `tabcomponent_${name}`;
      const refs = this.$refs[activeTabComponentRefName];

      Object.keys(this.$refs).forEach(refName => {
        if (refName !== activeTabComponentRefName) {
          const inactiveTabComponentRefs = this.$refs[refName];
          if (inactiveTabComponentRefs) {
            const inactiveTabComponentRef = refs[0];

            if (inactiveTabComponentRef.didBecomeInactive)
              inactiveTabComponentRef.didBecomeInactive();
          }
        }
      });

      if (refs) {
        const activeTabComponent = refs[0];

        if (activeTabComponent && activeTabComponent.didBecomeActive)
          activeTabComponent.didBecomeActive(name);
      }
    },
  },
  mounted() {
    this.changePage(this.initialPage);
  },
};
</script>

<style lang="scss" scoped>
.hg-tab-content {
  border: 2px solid #273548;
  border-radius: 4px;
  border-top-left-radius: 0;
}

.hg-tab-pane {
  opacity: 0;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s;
}

.hg-tab-pane.is-shown {
  opacity: 1;
  position: static;
  pointer-events: all;
}

.nav-tabs {
  border: none;
  margin-bottom: 1px;
}

.nav-tabs .nav-item {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ffffff;
  color: #005232;
}

.nav-tabs .nav-item.active {
  border-top: 4px solid #038352;
  background-color: #273548;
  color: #fff;
}

.nav-tabs .nav-link.active:focus {
  border-top-color: #273548;
}

.nav-tabs .nav-link:hover {
  border-color: transparent transparent transparent;
}

.nav-tabs .nav-link.active:hover {
  border-color: #273548 #c8ced3 transparent #c8ced3; // top | right | bottom | left
}
</style>