<template>
  <div class="husguiden" id="app">
    <nav-menu :isLoggedIn="isLoggedIn" :user="user"></nav-menu>
    <div class="container">
      <spinner ref="spinner"></spinner>
    </div>
    <router-view
      :isLoggedIn="isLoggedIn"
      :user="user"
      :profileConfigs="profileConfigurations"
      :activeProfileConfigId="activeProfileConfigId"
      :sharedMethods="{changeProfileConfig, addUserLoadedHandler}"
      :isLoading="isLoading"
      :showModal="showModal"
    ></router-view>
    <div class="footer" style="margin-top: 14px;">
      © Copyright 2021 HUSGUIDEN / Byggfakta Sverige i Ljusdal AB
      Östernäsvägen 1, 827 81 Ljusdal
      Tel 0651-55 25 00
      info@husguiden.nu
      www.husguiden.nu
      Orgnr 556324-9100
      <router-link v-if="$i18n.locale == 'sv'" to="/policy">{{$t('Privacy policy')}}</router-link>
      <a
        v-if="$i18n.locale == 'nb'"
        href="https://www.byggfakta.no/retningslinjer/sider.html"
      >{{$t('Privacy policy')}}</a>
    </div>
    <modal ref="modal"></modal>
  </div>
</template>

<script>
import NavMenu from './components/NavMenu/index';
import Spinner from './components/Spinner';
import Modal from './components/Modal';
import EventBus from './shared/EventBus';
import router from './shared/router';
import { authService, userService } from './shared/services';
import FolderFlipbook from './components/FolderFlipbook';

export default {
  name: 'app',
  data() {
    return {
      userId: null,
      user: null,
      activeProfileConfigId: null,
      isLoading: false,
      promises: [],
      profileConfigurations: {},
      userLoadedHandlers: [],
      userIsLoaded: false,
    };
  },
  computed: {
    isLoggedIn() {
      return !!this.user;
    },
  },
  components: {
    NavMenu,
    Spinner,
    Modal,
    FolderFlipbook
  },
  methods: {
    setSpinnerActiveState(state) {
      return this.$refs.spinner && this.$refs.spinner.setActiveState(state);
    },
    handleOnLogin(userId) {
      this.userId = userId;
      userService.getUser(this.userId).then(({ data }) => {
        const user = data[0];

        if (user !== undefined && user !== null) {
          if (!this.activeProfileConfigId) {
            this.activeProfileConfigId = 0;

            if (user.userName === 'demo') this.activeProfileConfigId = -1;
          }

          this.user = user;
          this.userIsLoaded = true;
          this.setProfileConfigurations(this.user.myProfiles);
          this.callUserLoadedHandlers();
        }
      });
    },
    handleOnLogout() {
      this.userId = null;
      this.user = null;
      this.activeProfileConfigId = null;
      this.$router.push('/');
    },
    handleLoggedInStateChanged(state) {
      if (state == null) return;
      if (!state) router.go('/login');
    },
    changeProfileConfig(profileConfigId) {
      this.activeProfileConfigId = profileConfigId;
    },
    showModal(title, bodyComponent, data, footerComponent) {
      return this.$refs.modal.open({
        title,
        subtitle: `${this.$t('Registered:')} ${data.plans.registerDate}`,
        bodyComponent,
        componentProps: data,
        footerComponent,
      });
    },
    setProfileConfigurations(profiles) {
      const maxProfilesInLabel = 4;
      let profileIdCursor = 0;
      const profileConfigurations = {
        [-1]: {
          name: 'demo',
          profileId: 0,
          editionId: null,
          hidden: true,
        },
      };
      const combinedProfilesMap = new Map();

      profiles.forEach((profile) => {
        if (combinedProfilesMap.has(profile.id)) {
          const combinedProfile = combinedProfilesMap.get(profile.id);
          combinedProfile.names.push(profile.name);
          combinedProfile.editionIds.push(profile.editionId);
          
          combinedProfilesMap.set(profile.id, combinedProfile);
        } else {
          const combinedProfile = {
            ...profile,
            names: [profile.name],
            editionIds: [profile.editionId],
          };

          combinedProfilesMap.set(profile.id, combinedProfile);
        }
      });

      Array.from(combinedProfilesMap.keys()).forEach(key => {
        const combinedProfile = combinedProfilesMap.get(key);
        const restCount = combinedProfile.editionIds.length - maxProfilesInLabel;

        let profileName = `${combinedProfile.names
          .slice(0, maxProfilesInLabel)
          .join(', ')}`;

        if (restCount > 0) {
          profileName = `${profileName} ${this.$t('and %s more').replace(
            /%s/g,
            restCount,
          )}`;
        }

        profileConfigurations[profileIdCursor] = {
          name: profileName,
          profileId: combinedProfile.id,
          editionId: null,
          hidden: false,
        };
        profileIdCursor += 1;

        if (combinedProfile.editionIds.length > 1) {
          combinedProfile.editionIds.forEach((editionId, index) => {
            profileConfigurations[profileIdCursor] = {
              name: combinedProfile.names[index],
              profileId: combinedProfile.id,
              editionId,
              hidden: false,
            };
            
            profileIdCursor += 1;
          });
        }
      });

      this.profileConfigurations = profileConfigurations;
    },
    addUserLoadedHandler(handler) {
      if (this.userIsLoaded)
        return handler(this.user, this.profileConfigurations);

      this.userLoadedHandlers.push(handler);

      return new Promise(resolve => this.userLoadedHandlers.push(resolve));
    },
    callUserLoadedHandlers() {
      this.userLoadedHandlers.forEach(handler =>
        handler(this.user, this.profileConfigurations)
      );
    },
  },
  created() {
    if (authService.isLoggedIn) {
      authService.refreshToken();
    }
  },
  mounted() {
    EventBus.$on('lazy-spinner-active', state => (this.isLoading = state));
    EventBus.$on('spinner-state', this.setSpinnerActiveState);
    EventBus.$on('login', this.handleOnLogin);
    EventBus.$on('logout', this.handleOnLogout);
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
