class ProductionHistoryService {
  dataAccess;

  url;

  constructor(dataAccess) {
    this.dataAccess = dataAccess;
    this.url = `${this.dataAccess.API}Husguiden`;
  }

  getProductionHistory() {
    return this.dataAccess.getResponse(this.url);
  }

  getProductionHistoryByProfile(rinfoid) {
    return this.dataAccess.getResponse(`${this.url}/GetByRinfoid/${rinfoid}`);
  }

  getProductionHistoryPaging(pageIndex, pageSize) {
    return this.dataAccess.getResponse(`${this.url}/${pageIndex}/${pageSize}`);
  }

  getProductionHistoryPagingByRInfoId(rinfoid, pageIndex, pageSize) {
    return this.dataAccess.getResponse(`${this.url}/${rinfoid}/${pageIndex}/${pageSize}`);
  }

  getProductionHistoryPagingByEditionId(editionId, rInfoId, pageIndex, pageSize) {
    return this.dataAccess.getResponse(`${this.url}/edition/${editionId}/${rInfoId}/${pageIndex}/${pageSize}`);
  }

  searchPerson(searchQuery) {
    return this.dataAccess.getResponse(`${this.url}/${searchQuery}`);
  }
}

export default ProductionHistoryService;
