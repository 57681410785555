<template>
  <div class="container route-container">
    <div class="head-container control-section">
      <div class="head-container-part">
        <select-with-description
          v-if="user"
          name="profileCategory"
          :description="$t('Choose profile:')"
          :items="Object.keys(profileConfigs).filter(item => !profileConfigs[item].hidden)"
          :inline-description="true"
          :selectKey="item => item"
          :selectLabel="item => profileConfigs[item].name"
          :onChange="handleRequestChangeProfileConfig"
          :activeItem="activeProfileConfigId"
        ></select-with-description>
      </div>
    </div>
    <tab-view
      :pages="[
      { label: $t('All'), name: 'all', component: AllTabComponent },
      { label: $t('Answered'), name: 'answered', component: AnsweredTabComponent }
    ]"
      :tabProps="{
        user,
        activeProfileConfigId,
        sharedMethods,
        isLoading,
        showModal,
        profileConfigs,
        updatePageSettings,
        dispatches,
        totalDispatchCount,
        handleRequestChangePage,
        handleRequestChangePageSize,
        pageSize,
        pageIndex,
        handleRequestOpenDialog,
        calculatedPageCount,
      }"
      initialPage="all"
    ></tab-view>
  </div>
</template>

<script>
import SelectWithDescription from '@/components/SelectWithDescription';
import Search from '@/components/Search';
import TabView from '@/components/TabView';
import DetailDialogBody from '@/components/DetailDialogBody';
import AnsweredTabComponent from './tabs/answered';
import AllTabComponent from './tabs/all';
import LazySpinner from '@/shared/LazySpinner';
import {
  productionHistoryService,
  productionDetailService,
  authService,
} from '@/shared/services';

export default {
  props: [
    'user',
    'activeProfileConfigId',
    'sharedMethods',
    'isLoading',
    'showModal',
    'profileConfigs',
  ],
  components: {
    SelectWithDescription,
    TabView,
  },
  data() {
    return {
      pageSize: 10,
      pageIndex: 0,
      dispatches: [],
      totalDispatchCount: 0,
      AnsweredTabComponent,
      AllTabComponent,
    };
  },
  computed: {
    calculatedPageCount() {
      return Math.ceil(this.totalDispatchCount / this.pageSize) || 1;
    },
  },
  created() {
    const { page, row_count: rowCount, profile } = this.$route.query;

    if (rowCount !== undefined) {
      this.pageSize = parseInt(rowCount);
    }

    if (page !== undefined) {
      this.pageIndex = parseInt(page);
    }

    if (profile !== undefined) {
      this.sharedMethods.changeProfileConfig(parseInt(profile));
    }
  },
  methods: {
    updateDispatches(profileConfigId, pageIndex, pageSize) {
      return this.sharedMethods.addUserLoadedHandler(
        (user, profileConfigurations) => {
          const setSpinnerNotNeeded = LazySpinner.show();
          const profileConfig = profileConfigurations[profileConfigId];

          if (!profileConfig) {
            const firstProfileConfigId = Object.keys(profileConfigurations)[0];

            this.$router.replace({
              path: '/production-history',
              query: {
                profile: firstProfileConfigId,
              },
            });

            setSpinnerNotNeeded();
            return this.updatePageSettings({ profileConfigId: firstProfileConfigId });
          }

          if (profileConfig.editionId !== null) {
            return productionHistoryService
              .getProductionHistoryPagingByEditionId(
                profileConfig.editionId,
                profileConfig.profileId,
                pageIndex,
                pageSize,
              )
              .then(({ data }) => {
                const dispatches = data.productionHistories;
                this.dispatches = dispatches;
                this.totalDispatchCount = data.totalItems;
              })
              .finally(setSpinnerNotNeeded);
          }

          return productionHistoryService
            .getProductionHistoryPagingByRInfoId(
              profileConfig.profileId,
              pageIndex,
              pageSize
            )
            .then(({ data }) => {
              this.dispatches = data.data;
              const firstItem = data.data[0];

              if (firstItem && firstItem.totaltAntalLeveranser) {
                this.totalDispatchCount = firstItem.totaltAntalLeveranser;
              }
            })
            .finally(setSpinnerNotNeeded);
        }
      );
    },
    updatePageSettings({
      pageSize = this.pageSize,
      pageIndex = this.pageIndex,
      profileConfigId = this.activeProfileConfigId,
    }) {
      const doneNavigatingPromise = Promise.resolve();
      const updatePromise = Promise.resolve();

      if (profileConfigId !== undefined && profileConfigId !== null) {
        updatePromise.then(() =>
          this.updateDispatches(profileConfigId, pageIndex, pageSize)
        );

        updatePromise.finally(() => {
          this.pageSize = pageSize;
          this.pageIndex = pageIndex;
          this.sharedMethods.changeProfileConfig(profileConfigId);

          const currentQuery = this.$route.query;
          let anySettingDiffs = false;

          if (parseInt(currentQuery.page) !== pageIndex) anySettingDiffs = true;

          if (parseInt(currentQuery.row_count) !== pageSize)
            anySettingDiffs = true;

          if (parseInt(currentQuery.profile) !== profileConfigId)
            anySettingDiffs = true;

          if (anySettingDiffs) {
            doneNavigatingPromise.then(() =>
              this.$router.replace({
                path: '/production-history',
                query: {
                  page: pageIndex,
                  row_count: pageSize,
                  profile: profileConfigId,
                },
              })
            );
          }
        });
      }

      return Promise.all([updatePromise, doneNavigatingPromise]);
    },
    handleRequestChangeProfileConfig(profileConfigId) {
      this.updatePageSettings({ profileConfigId });
    },
    handleRequestChangePage: function(pageIndex) {
      this.updatePageSettings({ pageIndex: Math.min(this.calculatedPageCount - 1, pageIndex) });
    },
    handleRequestChangePageSize: function(pageSize) {
      this.updatePageSettings({ pageSize });
    },
    handleRequestOpenDialog(item) {
      if (item.cooId) {
        const setSpinnerNotNeeded = LazySpinner.show();

        productionDetailService
          .getFollowUp(item.cooId)
          .then(({ data }) => {
            const plans = data.data[0];
            this.showModal(this.$t('Planned investment'), DetailDialogBody, {
              plans,
            });
          })
          .finally(setSpinnerNotNeeded);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
