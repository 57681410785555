<template>
  <div class="select-with-description">
    <label
      v-if="description"
      class="small"
      :class="inlineDescription && 'inline-description'"
      :for="name"
    >{{description}}</label>
    <div class="dropdown" :class="inlineDescription && 'inline-dropdown'">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        :name="name"
        :disabled="!items || items.length < 1"
      >{{activeItemLabel || getCurrentLabel() || ''}}</button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenu">
        <a
          class="dropdown-item"
          v-for="item in items"
          :key="selectKey(item)"
          @click="handleSelect(item)"
        >{{selectLabel(item)}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'description',
    'items',
    'selectKey',
    'selectLabel',
    'placeholder',
    'inlineDescription',
    'onChange',
    'name',
    'activeItem',
  ],
  data() {
    return {
      activeItemKey: this.activeItem,
      activeItemLabel: null,
    };
  },
  methods: {
    handleSelect: function(item) {
      if (this.onChange) {
        this.onChange(this.selectKey(item));
      } else {
        this.activeItemKey = this.selectKey(item);
      }
    },
    getCurrentLabel: function() {
      const activeItem = this.getItemByKey(this.activeItemKey);

      if (this.activeItemKey === undefined || !activeItem)
        return this.placeholder || this.$t('Choose option');

      return this.selectLabel(activeItem);
    },
    getItemByKey: function(key) {
      if (!this.items) return;
      const items = this.items.filter(item => this.selectKey(item) == key);

      return items[0];
    },
    setActiveItem: function(itemKey) {
      this.activeItemKey = itemKey;
    },
  },
  watch: {
    activeItem: function(currentValue) {
      this.activeItemKey = currentValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.select-with-description {
  cursor: default;
  user-select: none;
}

.inline-description {
  display: inline-block;
  margin-right: 8px;
}

.inline-dropdown {
  display: inline-block;
}

.dropdown-menu {
  max-height: 210px;
  overflow-y: scroll;
}
</style>