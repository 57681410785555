<template>
  <div class="container policy-content">
    <h1>
      <strong>Policy för datahantering, integritet och cookies</strong>
    </h1>

    <p>Byggfakta Sverige i Ljusdal AB respekterar din integritet och rätten att ha kontroll över dina personuppgifter. Vi kommer att vara öppna med vilken information vi samlar in och varför, och vi kommer skydda den på ett så säkert sätt som möjligt.</p>
    <p>I Byggfakta Sverige i Ljusdal ABs policy beskrivs vilka uppgifter vi samlar in, i vilket syfte de samlas in, på vilket sätt du kan ha kontroll över dina egna personuppgifter samt hur du kan kontakta oss. Denna personuppgiftspolicy gäller när du använder webbsidor, appar och tjänster som ingår under en app eller på en webbplats eller annan produkt/tjänst som tillhandahålls och administreras av Byggfakta Sverige i Ljusdal AB. Den gäller även dig som kund, partner, berättigat prospekt eller som aktiv aktör på projektmarknaden, tex du som lämnar information, eller förekommer i information som lämnas, till Byggfaktas informationsinsamling via telefon, e-post, formulär, enkäter eller på andra sätt. Byggfakta Sverige i Ljusdal AB är personuppgiftsansvarig i enlighet med dessa villkor och ansvarar för att behandling sker enligt gällande lagstiftning.</p>
    <p>För att vara tydliga och underlätta förståelsen för dig har vi delat upp policyn i två delar beroende på vilken relation du har till oss och vilken målgrupp du tillhör:</p>
    <p>
      <i>Läs först en kort sammanfattning under respektive del och sedan kan du läsa mer i detalj om den/de delar i policyn som passar bäst in på dig.</i>
    </p>

    <br />

    <h3>
      <strong>1) Här kan du se en sammanfattning över vår informationsinsamling under namnet "Byggsverige".</strong>
    </h3>
    <h4>
      <u>Vilka</u> personuppgifter vi samlar in
      <strong>i vår ständigt pågående marknadsundersökning om byggprojekt och projektmarknaden samt</strong>
      {{' '}}
      <u>hur</u>{{' '}}<strong>och</strong>{{' '}}<u>varför</u>.
    </h4>

    <p>
      <i>Kort sammanfattning</i>
    </p>

    <ul>
      <li>Vi samlar in personuppgifter utifrån stor branschnytta som berättigat intresse</li>
      <li>
        <strong>Men, inga känsliga personuppgifter,</strong> endast personuppgifter om personerna i egenskap av sina funktioner/yrkesroller på företag som på något sätt är involverade i byggprojekt
      </li>
      <li>Vi samlar in personuppgifter bland annat i syften för att möjliggöra kvalitetssäkring av projektinformation, marknadsföring av och informationsspridning om byggprojekt, t ex. planer, anbud och upphandlingar på projekt, för att möjliggöra byggstatistik, utbyte av erfarenheter, referensprojekt, för att möjliggöra kontakter mellan köpare och säljare, samt för att möjliggöra marknadskommunikation, marknadsföring, produktinformation, branschnyheter, erbjudanden, rekrytering, enkäter, undersökningar eller forskning. Slutligen kan tredje part (vår kund eller partner) även vara en myndighet och i de fallen samlas personuppgifterna in i syfte att hjälpa dem i sin myndighetsutövning.</li>
      <li>Vi sparar vissa personuppgifter i nordens största databas för byggprojekt och relaterade företag och beslutsfattare</li>
      <li>Vi sparar vissa personuppgifter om beslutsfattare på företag som vi bedömer vara relevanta aktörer med branschnytta (kunder, prospekts, partners) i CRM-system</li>
      <li>Vi behandlar angivna personuppgifter genom att skicka in dem i integrerade system och tjänster där vi själva, våra kunder och branschaktörer, i eller på något sätt relaterade till byggbranschen, kan använda dem enligt de syften och ändamål vi beskriver i vår policy och våra avtal med dem</li>
      <li>Våra kunder är själva personuppgiftsansvariga för sin egen behandling av de personuppgifter de ev. tillhandahåller via våra tjänster, men de ska behandla personuppgifterna i enlighet med de ändamål som beskrivs i vår policy</li>
      <li>Viss profilering kan också göras inom ramarna för samma ändamål som beskrivs i policyn</li>
    </ul>

    <div class="read-more">
      <button type="button"
              class="btn btn-primary read-more-button"
              @click="toggleLabel1Visibility">
        <span>{{label1IsHidden ? $t('Läs mer') : $t('Förminska')}}</span>
        <!--<i class="fa fa-caret-down" aria-hidden="true"></i>-->
      </button>

      <div class="read-more-text2" v-if="!label1IsHidden">
        <h3>Om vår ständigt pågående marknadsundersökning på byggprojektmarknaden</h3>

        <p>
          Under namnet Byggsverige bedriver vi en ständigt pågående marknadsundersökning där vi tar reda på vem som bygger eller planerar att bygga vad, var, när och hur på byggprojektmarknaden. Det handlar om information som kretsar kring planerade eller aktuella
          <strong>
            <u>byggprojekt</u>
          </strong>.
        </p>
        <p>Läs mer om nyttan för dig och byggbranschen här.</p>
        <h4>
          <strong>Behandling av ev. personuppgifter, i samband med vår ständigt pågående marknadsundersökning av projektmarknaden, baseras på följande:</strong>
        </h4>

        <h3>1. Berättigat intresse, intresseavvägning</h3>

        <p>Grunden för vår informationshantering handlar om bygg- och anläggningsprojekt på projektmarknaden, privatmarknaden samt vilka personer och företag som är involverade i projekten.</p>
        <p>Genom detta bidrar vi till en effektivare, mer transparent och öppnare projektmarknad som möjliggör kontakter mellan köpare och säljare (upphandlare/inköpare och anbudsgivare) samt kontakter mellan aktörer som har planer på att bygga något som kan utbyta erfarenheter med, eller ta referenser från, aktörer som har byggt eller projekterat liknande projekt.</p>
        <p>Byggbranschen ett antal väl kända branschproblem (höga kostnader, låg effektivitet, svartarbete, arbetsmiljöproblem, korruption etc.). Utmaningarna handlar om att man behöver och vill effektivisera, digitalisera och göra branschen mer öppen och transparent. Genom att vara öppna och transparenta med vilka företag och personer som är involverade i olika projekt bidrar alla gemensamt till en ökad transparens vilket kan bidra till att motverka branschproblemen, öka effektiviteten, få ner kostnaderna, underlätta affärer med nya aktörer inom Europas gränser.</p>
        <p>För att få in rätt information om rätt byggprojekt, rätt företag och vid rätt tidpunkter är vi och branschen i behov av att prata med rätt personer och ha personuppgifter om rätt personer för att kunna kontakta dem. Det handlar inte om känsliga personuppgifter utan uteslutande om personuppgifter om personerna i egenskap av sina funktioner/yrkesroller på företag som på något sätt är involverade i byggprojekt. Namn, ev. befattning på företaget eller roll i byggprojekt samt mailadress och telefonnummer är exempel på sådana personuppgifter.</p>
        <p>Vår egen och våra juristers bedömning är att intresset och nyttan för byggbranschen med vår verksamhet väger tyngre än dessa yrkespersoners integritet. Vi anser oss ha legitima ändamål med de, begränsade och icke känsliga, personuppgifter som behandlas.</p>

        <h3>2. Nödvändighet för att fullgöra (ofta överenskommen) kvalitetssäkring, som intresseavvägning</h3>
        <p>Även för att kunna fullgöra det vi ofta kommer överens med personer vi pratar med om byggprojekt, behövs vissa personuppgifter. T ex. för kvalitetssäkring och korrektur via e-post och inloggning till de involverade personerna i projekten. Vi överenskommer även ofta om att återkomma via telefon för att uppdatera informationen om projekten.</p>
        <p>Då detta inte är ett formellt avtal utan en överenskommelse och intresseavvägning vi gör handlar vår bedömning om att behandlingen av dessa, ej känsliga personuppgifter, enligt denna policy, inkl. nyttan för personen, personens företag och branschen väger högre än den registrerades intresse av skydd mot kränkning av den personliga integriteten.</p>

        <h3>De personuppgifter vi samlar in, hur och när</h3>
        <p>Byggfakta Sverige i Ljusdal AB inhämtar uppgifterna i första hand direkt från dig som i din funktion/yrkesroll på ett företag är involverad i ett aktuellt eller planerat bygg- eller anläggningsprojekt. Alternativt inhämtas uppgifterna från en annan person som är involverad i samma projekt och i de fallen informeras du via korrektur som skickas via e-post och/eller att vi ringer upp dig separat efter att vi fått uppgifterna av en annan person. Vi gör ett antal försök att nå dig men vi kan inte göra en oproportionell ansträngning då vi i grunden ser stor branschnytta som en intresseavvägning samt att det handlar om ej känsliga personuppgifter om personer i sin yrkesroll.</p>
        <p>Exempel på personuppgifter vi samlar in är ditt namn, din funktion/yrkesroll på projektet (företaget), samt kontaktuppgifter till dig så som mobiltelefonnummer, direktnummer och e-postadress.</p>
        <p>Byggfakta Sverige i Ljusdal AB kan komma att uppdatera dina uppgifter från andra offentliga register om det är nödvändigt för att upprätthålla god registervård.</p>
        <p>Byggfakta Sverige i Ljusdal AB registrerar, eller kan komma att registrera dina personuppgifter i samband med:</p>
        <p>Att vi pratar med dig direkt (eller har kontakt via e-post) alternativt får dina uppgifter via en annan person som är involverad i samma projekt.</p>
        <p>Anmälan till en tjänst som erbjuds via de olika plattformar som Byggfakta Sverige i Ljusdal AB erbjuder, t ex. inloggning på Byggsverige.se eller liknande där såväl uppgifter om projekt och personuppgifter kan uppdateras.</p>
        <p>När du fyller i ditt namn, din e-postadress eller ditt mobiltelefonnummer på webbplatsen.</p>
        <p>Om du kontaktar oss via e-post, via telefon, våra webbplatser och social media.</p>
        <p>Om du anmäler dig till nyhetsbrev eller liknande</p>
        <p>Ditt besök på våra webbplatser och app, inklusive men inte begränsat till trafikdata, platsdata, webbloggar och annan kommunikationsdata samt t.ex. den IP-adress, typ av enhet, operativsystem och webbläsartyp som används för besöket.</p>

        <h3>Insamling av personuppgifter där du kontaktar oss först</h3>
        <p>I samband med att du fyller i ett intresseformulär på hemsidan (t.ex. då du fyller i och marknadsför projekt via formulär på Byggsverige.se eller loggar in på Byggsverige.se) ges du möjlighet att läsa vår policy och klicka på en knapp där du säger att du förstår vår policy. Eftersom vi i grunden även i dessa fall hänvisar till intresseavvägning, d v s att branschnyttan väger tyngre än den registrerades intresse av skydd mot kränkning av den personliga integriteten, jobbar vi inte med begreppet samtycke. Däremot är vi öppna och lyfter fram vår policy i samband med att du fyller i dessa formulär.</p>
        <p>När du besöker vår hemsida informeras du även om cookies, både så kallad förstaparts och tredjeparts cookies på din dator. Sådana cookies följer dig och ditt surfbeteende i syfte att kunna erbjuda dig information, marknadsföring eller gratistjänster som baseras på ditt beteende.</p>
        <p>Hur vi använder insamlad information - ändamål och nytta</p>
        <p>
          Byggfakta Sverige i Ljusdal AB behandlar personuppgifter i huvudsak för de ändamål som anges nedan
          <i>samt för de eventuella ytterligare ändamål som anges vid insamlingstidpunkten</i>:
        </p>

        <h3>A) Ändamål och nytta med att säkerställa rätt information om rätt byggprojekt i kontakten med vår informationsinsamling ”Byggsverige”</h3>
        <p>För att fullgöra (ofta överenskommen) kvalitetssäkring, korrektur om projektinformation samt ev. inloggningar till tjänster via en av de plattformar som erbjuds av Byggfakta Sverige i Ljusdal AB;</p>
        <p>För att som överenskommet kunna återkomma via telefon eller e-post och uppdatera information om byggprojekt.</p>
        <p>För att möjliggöra god service, som att hantera dina förfrågningar, rätta ev. felaktiga uppgifter eller skicka information som du har begärt t.ex. korrektur eller tips på referensprojekt, tips på aktörer som varit involverade i liknande projekt etc.</p>
        <p>För att genom analys av ditt beteende på våra webbplatser och app utveckla, leverera och förbättra våra produkter och tjänster.</p>
        <p>Personuppgifter används även vid analys för att utföra segmentering/profilering, för att kunna utveckla riktade erbjudanden och tjänster samt för att genomföra undersökningar;</p>
        <p>För att skicka dig information och marknadsföring via SMS, e-post, app, push eller andra digitala kontaktvägar såsom t.ex. sociala medier då du har en aktiv relation med oss;</p>
        <p>För att kontakta dig via telefon, SMS, e-post, app eller post om andra erbjudanden, kampanjer eller tjänster som vi tror kan vara av intresse för dig i din roll på byggprojekt, observera att du när som helst kan tacka nej till denna marknadsföring;</p>
        <p>För att kunna ge dig relevanta rekommendationer, referenser, erbjudanden och anpassade tjänster baserat på du har för typ av byggprojekt, vad andra med liknande projekt eller beteendemönster har intresserat sig för, vad du har valt för tjänst, tittat på och visat intresse för;</p>
        <p>För att hjälpa oss att utveckla vår webbplats och app att vara mer användbara och för att förbättra din användarupplevelse av de plattformar som erbjuds via Byggfakta Sverige i Ljusdal AB genom att kunna anpassa visningen av tjänsterna till den enhet som används</p>
        <p>För att skicka viktiga meddelanden som kommunikation om förändringar i våra villkor och policyer</p>
        <p>I vissa fall av samtycke kan även personuppgifter sparas för att undvika att dina uppgifter dyker upp igen på nya projekt. En förutsättning för att hålla reda på om du t ex. inte vill ha e-post, direktmarknadsföring etc. är att spara vissa personuppgifter för att kunna uppfylla dina önskemål och din överenskommelse med oss om det.</p>

        <strong></strong>

        <h3>B) Ändamål för branschnytta</h3>
        <p>
          För att möjliggöra
          <strong>ökad transparens, öppenhet och effektivitet</strong> på byggprojektmarknaden
        </p>
        <p>
          Som en följd av ovan även för att öka
          <strong>informations- och erfarenhetsutbyte, få ner byggkostnaderna</strong> (via fler och bättre anbud, offerter, förslag lösningar), minska korruption, svartarbete och arbetsmiljöproblem.
        </p>
        <p>
          För att
          <strong>möjliggöra kontakter mellan köpare, beslutsfattare, projektledare, föreskrivare, påverkare och säljare av varor och tjänster</strong> (upphandlare/inköpare, konsulter och anbudsgivare etc.) på byggprojektmarknaden och
          <strong>underlätta affärer mellan företag som på något sätt är relaterade till byggbranschen, inom de europeiska gränserna</strong>
        </p>
        <p>
          För att möjliggöra
          <strong>relevant, riktad marknadskommunikation, marknadsföring, produktinformation, branschnyheter, erbjudanden</strong> inom b2b projektmarknaden – kan i sig t ex. bidra till ökad kompetens, insikt i nya lösningar, inspiration, ett mer hållbart och effektivt byggande.
        </p>
        <p>
          För att möjliggöra
          <strong>unik byggstatistik</strong> som används av branschen sedan många år, en viktig och väl etablerad statistik om projektmarknadens utveckling historiskt. Personuppgifterna används inte direkt i statistiken men
          <strong>personuppgifterna är en förutsättning</strong> för att informationen om projekten ska kunna samlas in och löpande uppdateras så att statistiken kan finnas och hållas uppdaterad löpande. Branschaktörer och branschorganisationer använder statistiken för flera olika syften, bland annat för att planera, organisera och budgetera.
        </p>
        <p>
          Även i syfte till att kunna ställa frågor och göra
          <strong>undersökningar för affärsutveckling</strong> som hjälper oss och branschen att effektivisera kontakterna mellan köpare och säljare (beslutsfattare och påverkare) av varor och tjänster men också effektivisera branschen och projektmarknaden i stort. Det kan handla om att bidra till mer hållbart byggande och underlätta utveckling av nya tjänster, produkter och lösningar för branschen och branschens aktörer, byggherrar, konsulter, entreprenörer, återförsäljare, underentreprenörer, leverantörer, grossister, mäklare, uthyrare, myndigheter, etc...
        </p>
        <p>
          För att genomföra
          <strong>enkäter, undersökningar i forsknings- eller utredningssyfte</strong>. Vi har t ex. genomfört ett antal samarbeten med universitet och branschorganisationer i olika syften, för att få fram beslutsunderlag till byggbranschen, för att effektivisera projektmarknaden. Personuppgifterna används inte direkt i forskningen eller undersökningarna men däremot är personuppgifterna helt nödvändiga för att vi ska kunna ställa rätt frågor och få rätt svar från rätt personer på rätt företag om rätt projekt. Forskningsprojekt och utredningar/undersökningar kan både genomföras via e-post och telefon. Exempel på forskning och utredning/undersökningar har t ex. handlat om produktivitet, hållbart byggande, föreskrivningsgrader, kännedom och syn på olika frågor kring aktörer, varumärken, lösningar, material, varor och tjänster på projektmarknaden.
        </p>
        <p>
          Ett annat ändamål kan ibland även vara
          <strong>journalistiska syften</strong> - för att underlätta vilka företag och personer journalister i branschmedier ska intervjua om olika projekt och branschfrågor.
        </p>
        <p>Andra ändamål för användning av personuppgifter kan även vara att lärare och studenter på universitet och högskolor vill komma i kontakt med beslutsfattare på rätt företag och rätt projekt, t ex. till examensarbeten, för att hitta praktikplatser på företag/projekt.</p>
        <p>
          I de fall personuppgifter som vi samlar in överlåts till kunder, partners eller branschaktörer är
          <strong>de själva personuppgiftsansvariga för sin egen behandling</strong> av de personuppgifter de tillhandahåller via våra tjänster. De får enligt avtal endast använda personuppgifterna inom ramen för de ändamål som beskrivs i vår policy - såvida de inte redan själva t ex. har pågående relationer (kunder, leverantörer, samarbetsparters) med eller har fått andra, egna samtycken från samma berörda personer som förekommer bland våra personuppgifter.
        </p>
      </div>
    </div>

    <h3>
      <strong>2. För dig som är, har varit kund, som varit eller som vi bedömt borde vara intresserad av vår information och tjänster.</strong>
    </h3>

    <h4>
      Här kan se en sammanfattning över
      <u>vilka</u> personuppgifter vi samlar in,
      <u>hur</u> och
      <u>varför</u>.
      <strong>Du får även svar på vad som händer på webbplatsen under ditt besök hos oss eller kommer i kontakt med oss på andra sätt:</strong>
    </h4>

    <ul>
      <li>Vi samlar in personuppgifter utifrån din och byggbranschens nytta som berättigat intresse</li>
      <li>
        <strong>Men, inga känsliga personuppgifter,</strong> endast personuppgifter om personer i egenskap av sina funktioner/yrkesroller, t ex. VD, ägare eller annan ledande befattning samt andra befattningar inom sälj-, marknad-, produkt-, beslutsstöd-, analys-, HR-avdelningar etc., på företag som på något sätt är eller borde vara intresserade av byggprojektmarknaden och/eller aktörer på den marknaden.
      </li>
      <li>Vi spårar din webbaktivitet och kan komma att använda den i marknadssyfte</li>
      <li>Vi samlar in personuppgifter vid särskilda tillfällen i syfte för marknadskommunikation, produktinformation och kundkommunikation</li>
      <li>Vi behandlar angivna personuppgifter genom att skicka in dem i integrerade system, t ex. CRM-system</li>
      <li>Vi sparar personuppgifter i vårt system för marknadsanalys</li>
      <li>Vi utför automatisk profilering baserat på webbaktivitet, och din profil kan användas i marknadssyfte</li>
    </ul>

    <div class="read-more">
      <button type="button"
              class="btn btn-primary read-more-button"
              @click="toggleLabel2Visibility">
        <span>{{label2IsHidden ? $t('Läs mer') : $t('Förminska')}}</span>
        <!--<i class="fa fa-caret-down" aria-hidden="true"></i>-->
      </button>

      <div class="read-more-text2" v-if="!label2IsHidden">
        <h3>Personuppgifter vi samlar in</h3>
        <p>Byggfakta Sverige i Ljusdal AB inhämtar uppgifterna i första hand direkt från dig som kund. Exempel på personuppgifter vi samlar in är ditt namn, ev. titel, kontaktuppgifter till dig så som mobiltelefonnummer och e-postadress. Byggfakta Sverige i Ljusdal AB kan komma att uppdatera dina uppgifter från andra offentliga register om det är nödvändigt för att upprätthålla god registervård.</p>
        <p>Byggfakta Sverige i Ljusdal AB registrerar, eller kan komma att registrera dina personuppgifter i samband med:</p>
        <p>Anmälan till en tjänst som erbjuds via de olika plattformar som Byggfakta Sverige i Ljusdal AB erbjuder.</p>
        <p>När du fyller i din e-postadress eller ditt mobiltelefonnummer på webbplatsen.</p>
        <p>Om du kontaktar oss via e-post, via telefon, våra webbplatser och social media.</p>
        <p>Om du anmäler dig till nyhetsbrev eller liknande.</p>
        <p>Ditt besök på våra webbplatser och app, inklusive men inte begränsat till trafikdata, platsdata, webbloggar och annan kommunikationsdata samt t.ex. den IP-adress, typ av enhet, operativsystem och webbläsartyp som används för besöket.</p>

        <h3>Insamling med samtycke</h3>
        <p>Genom att du fyller i ett samtyckesformulär på hemsidan (t.ex. i samband med intresseformulär, prenumerera på nyhetsbrev, ta del av erbjudanden m.m.) så samtycker du till att dina personuppgifter (ex. ditt namn, telefonnummer och e-postadress) får användas i sälj- och marknadsföringssyfte för de tjänster som exponeras i anslutning till där du ombeds fylla i dina personuppgifter.</p>
        <p>Vi samlar även in din IP-adress, geografisk position och intressen i syfte att kunna bättre anpassa vår marknadsföring till dig.</p>
        <p>När du besöker vår hemsida samtycker du även till att vi får lägga cookies, både så kallad förstaparts och tredjeparts cookies på din dator. Sådana cookies följer dig och ditt surfbeteende i syfte att kunna erbjuda dig marknadsföring som baseras på ditt beteende.</p>

        <h3>Hur vi använder insamlad information</h3>
        <p>Byggfakta Sverige i Ljusdal AB behandlar personuppgifter i huvudsak för de ändamål som anges nedan samt för de eventuella ytterligare ändamål som anges vid insamlingstidpunkten:</p>

        <p>För att fullgöra beställningar av tjänster via en av de plattformar som erbjuds av Byggfakta Sverige i Ljusdal AB;</p>
        <p>Möjliggöra god kundservice, som att hantera dina förfrågningar, rätta felaktiga uppgifter eller skicka information som du har begärt t.ex. nyhetsbrev;</p>
        <p>Uppgifter om beställning av tjänst lagras och analyseras, samt ligger till grund för erbjudanden och rabatter samt marknadsföring av både generell och riktad karaktär;</p>
        <p>För att kunna administrera kundprofiler, genomföra analyser samt marknadsundersökningar;</p>
        <p>För systemadministration och för att ta fram statistiska data om våra användares beteende och mönster men detta identifierar ingen enskild person utan sker på aggregerad nivå;</p>
        <p>För att genom analys av ditt beteende på våra webbplatser och app utveckla, leverera och förbättra våra produkter och tjänster.</p>
        <p>Personuppgifter används även vid analys för att utföra segmentering/profilering, för att kunna utveckla riktade erbjudanden och tjänster</p>
        <p>För att skicka dig information och marknadsföring via SMS, e-post, app, push eller andra digitala kontaktvägar såsom t.ex. sociala medier då du har en aktiv kundrelation med oss;</p>
        <p>För att kontakta dig via app, telefon, SMS, e-post eller post om andra erbjudanden, kampanjer eller tjänster som vi tror kan vara av intresse för dig, observera att du när som helst kan tacka nej till denna marknadsföring;</p>
        <p>För att kunna ge dig relevanta rekommendationer, erbjudanden och anpassade tjänster baserat på vad andra med liknande beteendemönster har intresserat sig för, vad du har valt för tjänst, tittat på och visat intresse för;</p>
        <p>För att hjälpa oss att utveckla vår webbplats och app att vara mer användbara och för att förbättra din användarupplevelse av de plattformar som erbjuds via Byggfakta Sverige i Ljusdal AB genom att kunna anpassa visningen av tjänsterna till den enhet som används;</p>
        <p>För att kunna spärra dina personuppgifter, t ex. mailadress för marknadsföring i de fall du tackat nej till marknadsföring;</p>
        <p>För att skicka viktiga meddelanden som kommunikation om förändringar i våra villkor och policyer;</p>
      </div>
    </div>

    <h3>Information som kan lämnas ut</h3>
    <p>Utöver att information enligt ovan beskrivna ändamål kan överlåtas till tredje part så som företagskunder och partners, finns även andra fall där vi kan dela med oss av information till andra företag som behandlar data på våra vägnar för att vi ska kunna utföra våra tjänster, t.ex. analys, distribution eller andra tjänster för att upprätthålla och tillämpa våra användarvillkor och leveransvillkor. Vid all hantering av personuppgifter iakttas dock alltid hög säkerhet och sekretess.</p>
    <p>Byggfakta Sverige i Ljusdal AB samarbetar endast med personuppgiftsbiträden som behandlar personuppgifter inom EU/EES eller som upprätthåller samma skyddsnivå som GDPR föreskriver.</p>

    <h3>Om cookies</h3>
    <p>När du använder vår webbplats eller appar kan det hända att personuppgifter samlas in via t.ex. cookies. Detta görs dock endast via ett separat samtycke. Om din webbläsare (eller annan motsvarande utrustning vid anropande av en webbplats) är inställd att tillåta cookies när du besöker Webbplatsen, anses du därigenom ha lämnat sitt samtycke till sådan användning. Då lagras informationen om din användning och vilka sidor som besöks. Det kan vara teknisk information om din enhet och internetuppkoppling såsom operativsystem, webbläsarversion, IP-adress, cookies och unika identifierare. Vid besök på våra webbplatser där våra tjänster tillhandahålls, kan olika tekniker användas för att känna igen dig i syfte att lära oss mer om våra användare. Detta kan ske direkt eller genom användning av teknik från tredje part.</p>

    <h3>Rättslig grund, lagring och gallring av personuppgifter</h3>
    <p>I och med att du lämnar uppgifter till Byggfakta Sverige i Ljusdal AB ger du din tillåtelse till att Byggfakta Sverige i Ljusdal AB registrerar och lagrar uppgifter om vilken tjänst du köpt samt behandlar de angivna personuppgifterna för angivna ändamål. Som legal grund för behandling kommer Byggfakta Sverige i Ljusdal AB att hänvisa till uppfyllande av avtal, berättigat intresse eller samtycke. Om Byggfakta Sverige i Ljusdal AB använder sig av berättigat intresse som grund, kommer det endast att göras i de syften och för de ändamål som angetts i vår policy.</p>
    <p>Observera att du närsomhelst kan återkalla ditt ev. samtycke genom att kontakta oss, för kontaktuppgifter se rubriken Så kontaktar du oss.</p>
    <p>Behandlingen sker enligt gällande lagstiftning och innebär att personuppgifter inte bevaras under en längre tid än vad som är nödvändigt med hänsyn till ändamålen med behandlingen. Byggfakta Sverige i Ljusdal AB kommer att lagra dina personuppgifter så länge du är kund hos oss eller har en annan relation med oss i enlighet med de ändamål vi beskriver i vår policy.</p>
    <p>Enligt dataskyddsförordningen ska vi ange under vilken period personuppgifterna kommer att lagras eller, om detta inte är möjligt, de kriterier som används för att fastställa denna period. I vår verksamhet är det inte möjligt att ange exakt hur länge personuppgifterna lagras så vi beskriver istället vår verksamhets kriterier för att vara så öppna och tydliga som möjligt.</p>
    <p>Då det gäller beslutsfattare på byggprojekt, som vi enligt vår policy behandlar enligt intresseavvägning, lagras uppgifterna så länge personerna är kopplade till ett byggprojekt. Byggprojekten kan i många fall vara aktuella i olika faser under många års tid, från idé, planering, projektering, upphandling, byggtid, renovering etc. fram till färdigställt projekt. Det är inte ovanligt att det i enskilda projekt kan handla om allt från några år till många år. Personerna förekommer ofta i flera projekt och återkommande i nya projekt och så länge de är relevanta för vår verksamhet, lagras dessa personuppgifter och behandlas i enlighet med de ändamål vi beskriver i vår policy. Personuppgifter relaterade till kunder, före detta kunder och prospekts, potentiella kunder behandlas på ett liknande sätt som beskrivits ovan. D v s så länge de jobbar kvar på företaget och har någon ledande roll eller roll inom sälj, marknad, analys etc. och vi anser att de är relevanta i enlighet med de ändamål vi beskriver i vår policy lagras deras personuppgifter.</p>
    <p>Beslutsfattarna vars personuppgifter vi behandlar kan självklart när som helst avsäga sig användning i marknadsföringssyfte och vi erbjuder på olika sätt skräddarsydda begränsningar i användningen. I vår ständigt pågående marknadsundersökning av projektmarknaden samt i vår ständigt pågående bearbetning av de aktörer som vi bedömer ha intresse av den information vi samlar in om projektmarknaden, uppdateras våra register löpande och personer som slutar, byter anställningar, avlider etc. får vi automatiskt kännedom om. Rättelser av personuppgifter är en naturlig del i vårt arbete eftersom vi regelbundet är i kontakt med företag och de beslutsfattare som planerar byggprojekt – och de vi bedömer är intresserade av dessa planerade byggprojekt.</p>
    <p>Beslutsfattarna återkommer ofta över tid på nya byggprojekt och för att bäst hjälpa dem som inte vill nås av direktmarknadsföring rekommenderar vi dem att vi lagrar deras personuppgifter även för att hålla reda på att de inte vill nås av direktmarknadsföring i en eller annan form. Skulle vi helt radera dem så är sannolikheten mycket stor att de läggs upp i våra register igen så snart de relateras till ett nytt byggprojekt och vi lägger då upp dem (igen) i enlighet med vår policy, utifrån intresseavvägning, men då utan att veta att de inte vill nås av direktmarknadsföring. Vi hanterar kunder, före detta kunder och potentiella kunder på ett motsvarande sätt.</p>
    <p>De som vill bli raderade raderar vi så länge vi inte bedömer att det finns några berättigade skäl för behandlingen som väger tyngre än den registrerades intressen av integritet. Är personuppgifterna inte längre nödvändiga för de ändamål för vilka de samlats in raderas de självklart. I många fall väljer de som inledningsvis kontaktat oss för att bli raderade någon form av skräddarsydd begränsning av behandlingen av deras personuppgifter.</p>
    <p>I t ex. forsknings eller utredningssyfte kan även namn på beslutsfattare som slutat på företag som varit involverade i byggprojekt lagras med tillägget att de just slutat på företaget. Deras kontaktuppgifter raderas men namnen kan finnas kvar för att det är av intresse för branschen och de ändamål vi beskriver i policyn att historiskt veta vilka beslutsfattare som varit involverade i vilka projekt.</p>
    <p>Även för lagkrav, som exempelvis bokföringslagen, kan personuppgifter behöva lagras i enlighet med gällande lagstiftning.</p>
    <p>Vid all hantering av personuppgifter iakttas alltid hög säkerhet och sekretess.</p>

    <h3>Dina rättigheter och val</h3>
    <p v-remove-whitespace>
      Du har rättigheter när det gäller dina personuppgifter och du har möjlighet att påverka din information och vad som sparas. Byggfakta Sverige i Ljusdal AB kommer på eget eller kundens initiativ att rätta uppgifter som upptäcks vara felaktiga. Du kan även närsomhelst begära att dina uppgifter raderas eller att dess användning begränsas* genom att kontakta oss (för kontaktvägar se rubriken Så kontaktar du oss). Om du anser att dina rättigheter inte respekteras av oss får du gärna kontakta Byggfakta Sverige i Ljusdal AB eller så kan du höra av dig till Integritetsskyddsmyndigheten (
      <a href="mailto:imy@imy.se">imy@imy.se</a>).
    </p>
    <p>Du kan när som helst avstå (helt eller delvis) från att ta emot marknadsföringskommunikation från oss genom att kontakta oss eller genom att avregistrera dig från vidare kommunikation i e-post och SMS som vi skickar dig. Du är alltid välkommen att kontakta vår kundtjänst för att få hjälp att ändra vilken sorts kommunikation du vill ha eller tacka nej till vår kommunikation.</p>
    <p>
      <i>*Vi erbjuder vad vi kallar skräddarsydda begränsningar – på dina villkor, enligt dina önskemål. Vi informerar också gärna om den nytta och de effekter dina val medför.</i>
    </p>
    <p>Du kan, en gång per år kostnadsfritt, erhålla information om vilka personuppgifter som är registrerade, genom att skriftligt begära ett s.k. registerutdrag från Byggfakta Sverige i Ljusdal AB. Din skriftliga begäran skickas till postadressen nedan och måste vara undertecknad av dig personligen.</p>

    <h3>Kort om GDPR</h3>
    <p>Dataskyddsförordningen kallas ibland kort för GDPR vilket står för General Data Protection Regulation. Förordningen börjar tillämpas 25 maj 2018 och ersätter då den svenska personuppgiftslagen (PUL).</p>
    <p>Det finns flera anledningar till varför GDPR införs men två av de största anledningar är:</p>

    <ul>
      <li>Att privatpersoner vill ha (och ska få) mer kontroll över hur deras personuppgifter används</li>
      <li>Att EU vill göra det enklare för företag att göra affärer inom de europeiska gränserna</li>
    </ul>

    <p>
      <i>Vi på Byggfakta Sverige i Ljusdal AB ser mycket positivt på detta och vi ser att det ligger i linje med vad vi själva vill med vår verksamhet som syftar just till att göra det enklare för företag och personer på företag att enklare och effektivare kunna göra affärer (och utbyta erfarenheter etc.) med varandra. Personer som jobbar på företag inom byggsektorn ska dessutom både få mer nytta genom de personuppgifter de har hos oss och få mer kontroll över dem.</i>
    </p>
    <p>
      <i>Statens offentliga utredning (SoU) menar vidare att GDPR inte har som syfte att påverka den marknadsföring som sker idag. Kontaktuppgifter till beslutsfattare (b2b) är visserligen personuppgifter, men branscher som vår har genom intresseavvägning större intressen av branschnytta att uppfylla, än vikten av att skydda harmlös personinformation. Däremot kräver företagsregister (b2b) självklart hög säkerhet, korrekthet, transparens om de personuppgifter som behandlas samt tydlighet kring ändamål och rättigheter.</i>
    </p>

    <h3>Länkar till andra webbplatser</h3>
    <p>I händelse av att vår webbplats innehåller länkar till tredje parts webbplatser, hemsidor, eller material publicerat hos tredje part, är dessa länkar endast för informationssyfte. Eftersom Byggfakta Sverige i Ljusdal AB saknar kontroll över innehållet på dessa webbplatser eller dess material ansvarar vi därför inte för dess innehåll. Byggfakta Sverige i Ljusdal AB ansvarar inte heller för skador eller förluster som skulle kunna uppstå vid användning av dessa länkar.</p>

    <h3>Så här kontaktar du oss</h3>
    <p>För ytterligare information om personuppgiftshantering eller om du har frågor, är du välkommen att kontakta oss på:</p>
    <p>Byggfakta Sverige i Ljusdal AB</p>
    <p>Östernäsvägen 1, 827 32 Ljusdal, organisationsnummer: 556324-9100.</p>
    <p>
      0651 - 55 25 00,
      <a href="mailto:info@byggfakta.se">info@byggfakta.se</a>.
    </p>
    <p>Denna policy gäller från och med 2018-03-01</p>
  </div>
</template>

<script>
import { mixin as removeWhitespaceMixin } from 'vue-remove-whitespace';

export default {
  mixins: [removeWhitespaceMixin],
  data() {
    return {
      label1IsHidden: true,
      label2IsHidden: true,
    };
  },
  methods: {
    toggleLabel1Visibility: function() {
      this.label1IsHidden = !this.label1IsHidden;
    },
    toggleLabel2Visibility: function() {
      this.label2IsHidden = !this.label2IsHidden;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 0px) {
  .footer {
    bottom: 10px;
  }
}

h3 {
  font-weight: bold;
}

h3,
h4 {
  margin-top: 20px;
}

p {
  margin-top: 15px;
}

ul li {
  margin-bottom: 10px;
}

.policy-content {
  .read-more {
  }

  .read-more .fa {
    float: right;
    margin-left: 10px;
    padding-top: 3px;
  }

  .read-more-text {
    display: none;
    margin-top: 20px;
    padding: 15px;
    background: #f3f3f3;
    border: 1px solid #ddd;

    h3:first-child {
      margin-top: 0;
    }
  }
}

.read-more-text2 {
  /*display: none;*/
  margin-top: 20px;
  padding: 15px;
  background: #f3f3f3;
  border: 1px solid #ddd;

  h3:first-child {
    margin-top: 0;
  }
}

#policy-overlay {
  position: fixed;
  width: 450px;
  z-index: 9999999999999999;
  padding: 30px 15px;
  bottom: 25px;
  display: none;
  right: 25px;
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  h4 {
    margin: 0;
  }
}
</style>
