import EventBus from './EventBus';
import wait from './wait';

const setSpinnerActiveState = state => EventBus.$emit('spinner-state', state);

let callCount = 0;

const LazySpinner = {
  show(timeout = 100) {
    callCount += 1;
    EventBus.$emit('lazy-spinner-active', true);
    let showSpinner = true;

    const setSpinnerNotNeeded = () => {
      callCount -= 1;

      if (callCount < 1) {
        callCount = 0;
        showSpinner = false;
        setSpinnerActiveState(false);
        EventBus.$emit('lazy-spinner-active', false);
      }
    };

    wait(timeout).then(() => {
      if (showSpinner) {
        setSpinnerActiveState(true);
      }
    });

    return setSpinnerNotNeeded;
  },
};

export default LazySpinner;
