<template>
  <tr class="interactive-list-item" :class="{'expanded-item': isExpandedItem}">
    <component :is="tableItemComponent" :item="item" v-bind="tableItemComponentProps"></component>
  </tr>
</template>

<script>
export default {
  props: [
    'item',
    'tableItemComponent',
    'isExpandedItem',
    'tableItemComponentProps',
  ],
};
</script>

<style lang="scss" scoped>
.expanded-item {
  background-color: #ddd;
}

.interactive-list-item.expanded-item:hover {
  background-color: #ddd;
}
</style>