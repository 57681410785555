<template>
  <div>
    <div class="container">
      <div
        v-if="loginMessage.value"
        class="alert"
        :class="`alert-${loginMessage.type}`"
        role="alert"
      >{{loginMessage.value}}</div>
      <h4>Välkommen</h4>

      <div class="form-group row">
        <label class="col-xs-12 col-sm-12 col-md-2 col-form-label">Användarnamn</label>
        <div class="col-xs-12 col-sm-12 col-md-3">
          <input class="form-control" type="text" v-model="username" @keyup.enter="login" />
          <br />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-xs-12 col-sm-12 col-md-2 col-form-label">Lösenord</label>
        <div class="col-xs-12 col-sm-12 col-md-3">
          <input class="form-control" type="password" v-model="password" @keyup.enter="login" />
          <br />
        </div>
      </div>

      <p>
        <button class="btn btn-success btn-lg" @click="login">Logga in</button>
      </p>
      <p style="font-size: 12px; font-style: italic;">
        Vill du se vilken information man kan se?
        <br />Prova logga in med
        <strong>demo/demo</strong>
      </p>
    </div>
  </div>
</template>

<script>
import { authService } from '../../shared/services';
import LazySpinner from '../../shared/LazySpinner';
import router from '../../shared/router';

export default {
  data() {
    return {
      username: '',
      password: '',
      invalidCredentials: false,
      loginMessage: {
        type: null,
        value: null,
      },
    };
  },
  methods: {
    login: function() {
      const setSpinnerNotNeeded = LazySpinner.show();
      const loginPromise = authService
        .login(this.username, this.password)
        .then(() => {
          // If we already tried to visit a page, redirect the user to that page
          if (authService.redirectUrl) {
            router.replace(this.authService.redirectUrl);
          } else {
            router.replace('/production-history');
          }
        })
        .catch(() => {
          this.setLoginMessage(
            this.$t(
              'Login error. Please check your login credentials and try again.'
            )
          );
        });

      loginPromise.finally(setSpinnerNotNeeded);
    },
    setLoginMessage(message, type) {
      this.loginMessage = {
        type: type || 'danger',
        value: message,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>