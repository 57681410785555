import JwtHelperService from './jwt-helper.service';

export default class TokenService {
  cookieService;

  constructor(cookieService) {
    this.cookieService = cookieService;
  }

  decodeToken() {
    const helper = new JwtHelperService();

    return helper.decodeToken(this.getTokenCookie());
  }

  get isLoggedIn() {
    const isTokenExpired = this.isTokenExpired();

    return !isTokenExpired;
  }

  getTokenCookie() {
    return this.cookieService.get('id_token');
  }

  setTokenCookie(value) {
    this.deleteTokenCookie();
    const expireDate = new Date();
    const numberOfDaysToAdd = 7;
    expireDate.setDate(expireDate.getDate() + numberOfDaysToAdd);

    this.cookieService.set('id_token', value, expireDate, '/');
  }

  deleteTokenCookie() {
    this.cookieService.delete('id_token', '/');
  }

  isTokenExpired() {
    const helper = new JwtHelperService();
    const isTokenExpired = this.getTokenCookie() ? helper.isTokenExpired(this.getTokenCookie()) : true;

    return isTokenExpired;
  }

  logout() {
    this.deleteTokenCookie();
  }
}
