<template>
  <div class="container fill-height">
    <div class="separate-children-container fill-height">
      <div class="head-container control-section">
        <div class="head-container-part">
          <search
            :onRequestSearch="handleRequestSearchPersons"
            :placeholder="$t('Search for persons in the deliveries')"
          ></search>
        </div>
      </div>
      <expandable-table
        v-if="viewMode === 'browse'"
        :columnNames="[
          $t('Delivery date'),
          $t('Number of delivery'),
        ]"
        :sortingFunctions="[dateSort, countSort]"
        :values="this.dispatches"
        :getKey="value => value.id"
        :tableItemComponent="BrowseListItem"
        :tableItemComponentProps="{editionId: profileConfigs[activeProfileConfigId] && profileConfigs[activeProfileConfigId].editionId}"
      ></expandable-table>
      <div v-if="viewMode === 'browse'" class="control-section">
        <paginator
          :itemCount="this.totalDispatchCount"
          :itemsPerPage="pageSize"
          :pageIndex="pageIndex"
          :onRequestChangePage="handleRequestChangePage"
          :onRequestChangePageSize="handleRequestChangePageSize"
          :label="$t('Deliveries per page:')"
        ></paginator>
      </div>
    </div>
    <div>
      <a
        v-if="viewMode == 'search'"
        @click="viewMode = 'browse'"
        class="nav-link buttonlike"
        style="color: black;"
      >
        <i class="cui-arrow-left"></i>
        {{$t('Go back')}}
      </a>

      <expandable-table
        v-if="viewMode == 'search' && searchResults.length > 0"
        :columnNames="[
          $t('Name'),
          $t('Municiple'),
          $t('Description'),
          $t('Delivery date'),
          $t('Planned investment'),
        ]"
        :sortingFunctions="[nameSort, municipleSort, descriptionSort, deliveryDateSort]"
        :values="searchResults"
        :getKey="value => value.cooId"
        :tableItemComponent="SearchResultItem"
        :expansionComponent="SearchResultItemExpansion"
        :tableItemComponentProps="{onRequestOpenDialog: handleRequestOpenDialog}"
        :expansionComponentProps="{onRequestOpenDialog: handleRequestOpenDialog}"
      ></expandable-table>
      <div
        class="status-message"
        v-if="viewMode == 'search' && searchResults.length < 1 && !isLoading"
      >{{$t('No results')}}</div>
    </div>
  </div>
</template>

<script>
import SelectWithDescription from '@/components/SelectWithDescription';
import Search from '@/components/Search';
import Paginator from '@/components/Paginator';
import SearchResultItem from './SearchResultItem';
import SearchResultItemExpansion from './SearchResultItemExpansion';
import {
  productionHistoryService,
  productionDetailService,
  authService,
} from '@/shared/services';
import LazySpinner from '@/shared/LazySpinner';
import ExpandableTable from '@/components/ExpandableTable';
import BrowseListItem from './BrowseListItem';
import TabView from '@/components/TabView';
import sortingFunctions from '@/shared/sorting';

export default {
  props: [
    'user',
    'activeProfileConfigId',
    'isLoading',
    'showModal',
    'profileConfigs',
    'dispatches',
    'totalDispatchCount',
    'pageSize',
    'pageIndex',
    'tabProps',
    'sharedMethods',
    'updatePageSettings',
    'handleRequestChangePage',
    'handleRequestChangePageSize',
    'handleRequestOpenDialog',
    'calculatedPageCount',
  ],
  components: {
    SelectWithDescription,
    Search,
    Paginator,
    ExpandableTable,
    TabView,
  },
  data() {
    return {
      viewMode: 'browse', // 'browse' or 'search'
      searchResults: [],
      expandedItemIndex: -1,
      SearchResultItemExpansion,
      SearchResultItem,
      BrowseListItem,
      Paginator,
      didSetProfileConfigFromQueryParams: false,
      shouldSetProfileConfigFromProps: false,
      tabIsActive: false,
    };
  },
  methods: {
    handleRequestSearchPersons: function(searchQuery) {
      if (searchQuery.length > 0) {
        this.viewMode = 'search';
        this.searchResults = [];
        const setSpinnerNotNeeded = LazySpinner.show();

        productionHistoryService
          .searchPerson(searchQuery)
          .then(({ data }) => {
            this.searchResults = data.data;
          })
          .finally(setSpinnerNotNeeded);
      }
    },
    didBecomeActive() {
      this.tabIsActive = true;
    },
    didBecomeInactive() {
      this.tabIsActive = false;
    },
    ...sortingFunctions,
  },
  watch: {
    viewMode: function() {
      this.expandedItemIndex = -1;
    },
    activeProfileConfigId: {
      immediate: true,
      handler(currentValue) {
        if (currentValue !== null && this.shouldSetProfileConfigFromProps) {
          this.updatePageSettings({
            profileConfigId: currentValue,
          });
        }
      },
    },
  },
  mounted() {
    if (!authService.isLoggedIn) {
      this.$router.push('/login');
      return;
    }

    const {
      page: pageParam,
      row_count: rowCountParam,
      profile: profileParam,
    } = this.$route.query;

    const page = parseInt(pageParam);
    const rowCount = parseInt(rowCountParam);
    const profileConfigId = parseInt(profileParam);

    if (isNaN(profileConfigId)) this.shouldSetProfileConfigFromProps = true;

    this.updatePageSettings({
      pageSize: isNaN(rowCount) ? this.pageSize : rowCount,
      pageIndex: isNaN(page) ? this.pageIndex : page,
      profileConfigId: isNaN(profileConfigId) ? this.activeProfileConfigId : profileConfigId,
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
