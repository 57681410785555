/* globals require process */
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import 'url-search-params-polyfill';
import App from './App.vue';
import router from './shared/router';
import '../node_modules/@coreui/coreui/dist/css/coreui.min.css'
import '../node_modules/@coreui/icons/css/free.min.css';
import '../assets/styles.scss';

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(faTimes);
library.add(faFileDownload);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: require('../assets/i18n/generated/translations.json'),
});

Vue.filter('translate', value => value);

new Vue({
  render: h => h(App),
  router,
  i18n,
}).$mount('#app');
