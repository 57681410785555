<template>
  <div
    :style="{display: 'contents'}"
    @click="$router.push({ name: 'production-detail', query: { dispatch_id: item.id }, params: { pageTitle: item.datum, editionId } })"
  >
    <td>{{item.datum}}</td>
    <td>{{item.antal}}</td>
  </div>
</template>

<script>
export default {
  props: ['item', 'editionId'],
};
</script>

<style lang="scss" scoped>
</style>