<template>
  <h6 class="profile-dropdown-item btn btn-husguiden" @click="this.handleOnClick">{{content}}</h6>
</template>

<script>
export default {
  props: ['onRequestCloseMenu', 'onClick', 'content'],
  methods: {
    handleOnClick() {
      this.onRequestCloseMenu();
      this.onClick();
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-dropdown-item {
  padding: 8px;
  text-align: center;
  margin-bottom: 0.5rem;
  color: var(--primary);
  display: block;
}

.profile-dropdown-item:hover {
  background-color: #eee;
}

.profile-dropdown-item:active {
  background-color: #ddd;
}
</style>
