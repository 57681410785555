<template>
  <div :style="{display: 'contents'}">
    <td>{{item.typ}}</td>
    <td>
      <a
        target="_blank"
        :href="`https://www.hitta.se/s%C3%B6k?vad=${[item.name, item.address, item.town].filter(a => a).join('+')}`"
      >
        <img width="18" height="18" src="../../../../assets/images/hitta.se.svg" />
      </a>
      {{item.name}}
    </td>
    <td>{{item.address}}</td>
    <td>{{`${item.postAddress} ${item.town}`}}</td>
    <td>{{item.phone}}</td>
    <td>{{item.buildLocation}}</td>
    <td>{{item.municiple}}</td>
    <td>{{item.value}}</td>
    <td>{{item.description}}</td>
  </div>
</template>

<script>
export default {
  props: ['item'],
};
</script>

<style lang="scss" scoped>
</style>
