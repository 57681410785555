<template>
  <div :style="{display: 'contents'}">
    <td :class="{'inactive': !item.name}">
      <a
        target="_blank"
        :href="`https://www.hitta.se/s%C3%B6k?vad=${[item.name, item.address, item.town].filter(a => a).join('+')}`"
      >
        <img
          width="18"
          height="18"
          :src="require('../../../../../../assets/images/hitta.se.svg')"
        >
      </a>
      {{ item.name || $t('(Name not specified)') }}
    </td>
    <!-- <td>{{item.address}}</td> -->
    <td>{{ item.municiple }}</td>
    <td>{{ item.description }}</td>
    <td>{{ isAnswer ? item.updateDate : item.deliveryDate }}</td>
    <td>
      <button
        v-if="item.hasSurveyAnswer"
        class="btn btn-husguiden"
        @click="onRequestOpenDialog(item)"
      >
        {{ $t('Open') }}
      </button>
    </td>
  </div>
</template>

<script>
export default {
  props: ['item', 'onRequestOpenDialog', 'isAnswer'],
};
</script>

<style lang="scss" scoped>
.inactive {
  color: rgb(175, 175, 175);
}
</style>
