<template>
  <div class="form-inline ml-auto">
    <input
      @keypress.enter="handleRequestSearch"
      ref="textInput"
      class="form-control button-sibling-right"
      type="text"
      :placeholder="placeholder"
      aria-label="Search"
      style="width: 300px;"
      spellcheck="false"
    />
    <button @click="handleRequestSearch" class="btn btn-husguiden">
      <i class="cui-magnifying-glass"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    onRequestSearch: {
      type: Function,
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleRequestSearch() {
      this.$refs.textInput.blur();
      this.onRequestSearch(this.$refs.textInput.value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
