import createValueParser from './valueParser';

const valueParser = createValueParser();

export default {
  dateSort(a, b) {
    if (a.datum === null) return 1;
    if (b.datum === null) return -1;

    const dateA = new Date(a.datum);
    const dateB = new Date(b.datum);

    if (dateA > dateB) {
      return -1;
    }

    if (dateB > dateA) {
      return 1;
    }

    return 0;
  },
  countSort(a, b) {
    if (a.antal === null) return 1;
    if (b.antal === null) return -1;

    if (a.antal > b.antal) {
      return -1;
    }

    if (b.antal > a.antal) {
      return 1;
    }

    return 0;
  },
  nameSort(a, b) {
    if (a.name === null) return 1;
    if (b.name === null) return -1;

    return b.name.localeCompare(a.name);
  },
  municipleSort(a, b) {
    if (a.municiple === null) return 1;
    if (b.municiple === null) return -1;

    return b.municiple.localeCompare(a.municiple);
  },
  descriptionSort(a, b) {
    if (a.description === null) return 1;
    if (b.description === null) return -1;

    return b.description.localeCompare(a.description);
  },
  deliveryDateSort(a, b) {
    if (a.deliveryDate === null) return 1;
    if (b.deliveryDate === null) return -1;

    const dateA = new Date(a.deliveryDate);
    const dateB = new Date(b.deliveryDate);

    if (dateA > dateB) {
      return -1;
    }

    if (dateB > dateA) {
      return 1;
    }

    return 0;
  },
  updateDateSort(a, b) {
    if (a.updateDate === null) return 1;
    if (b.updateDate === null) return -1;

    const dateA = new Date(a.updateDate);
    const dateB = new Date(b.updateDate);

    if (dateA > dateB) {
      return -1;
    }

    if (dateB > dateA) {
      return 1;
    }

    return 0;
  },
  typeSort(a, b) {
    if (a.typ === null) return 1;
    if (b.typ === null) return -1;

    return b.typ.localeCompare(a.typ);
  },
  addressSort(a, b) {
    if (a.address === null) return 1;
    if (b.address === null) return -1;

    return b.address.localeCompare(a.address);
  },
  postCodeSort(a, b) {
    if (a.postAddress === null) return 1;
    if (b.postAddress === null) return -1;

    const removeSpaces = text => text.replace(/ /g, '');
    const postCodeA = parseInt(removeSpaces(a.postAddress));
    const postCodeB = parseInt(removeSpaces(b.postAddress));

    if (postCodeA > postCodeB) {
      return -1;
    }

    if (postCodeB > postCodeA) {
      return 1;
    }

    return 0;
  },
  phoneNumberSort(a, b) {
    if (a.phone === null) return 1;
    if (b.phone === null) return -1;

    const onlyDigits = text => text.replace(/\D*/g, '');
    const phoneNumberA = parseInt(onlyDigits(a.phone));
    const phoneNumberB = parseInt(onlyDigits(b.phone));

    if (phoneNumberA > phoneNumberB) {
      return -1;
    }

    if (phoneNumberB > phoneNumberA) {
      return 1;
    }

    return 0;
  },
  locationSort(a, b) {
    if (a.buildLocation === null) return 1;
    if (b.buildLocation === null) return -1;

    return b.buildLocation.localeCompare(a.buildLocation);
  },
  valueSort(a, b) {
    if (a.value === null) return 1;
    if (b.value === null) return -1;

    const valueA = valueParser.parse(a.value);
    const valueB = valueParser.parse(b.value);

    if (valueA > valueB) {
      return -1;
    }

    if (valueB > valueA) {
      return 1;
    }

    return 0;
  },
};
