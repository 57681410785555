class AnsweredService {
  constructor(dataAccessHelper) {
    this.dataAccessHelper = dataAccessHelper;
  }

  getAnsweredByRInfoId(rInfoId) {
    return this.dataAccessHelper.getResponse(`${this.dataAccessHelper.API}husguiden/answered/${rInfoId}`);
  }

  getAnsweredByEditionId(editionId, rInfoId) {
    return this.dataAccessHelper.getResponse(
      `${this.dataAccessHelper.API}husguiden/answered/edition/${editionId}/${rInfoId}`,
    );
  }
}

export default AnsweredService;
