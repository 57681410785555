<template>
  <div class="container">
    <div class="col-xs-12">
      <div class="info-text-title info-text">
        Våra tips till dig för ett gott boende!
      </div>

      <div class="info-text">Har du fått Husguiden i brevlådan? Grattis!</div>

      <div class="info-text">
        Vi antar att du har huvudet fullt av byggplaner och hoppas att foldern
        kommer ge dig värdefulla tips om lokala butiker och tjänster. Kontakta
        företagen redan idag eller spar foldern för kommande affärer!
      </div>

      <div class="info-text">
        Passa på! Du vet väl att arbetskostnaden kan bli lägre med gällande
        ROT-avdrag!
      </div>

      <div class="info-text">
        Äger du flera fastigheter på olika håll i Sverige? Kontakta oss så
        hjälper vi dig hitta rätt kontakter.
        <a href="mailto:bestall@husguiden.nu">bestall@husguiden.nu</a>
      </div>

      <div class="info-text">
        Saknar du något i foldern? Vill du ha kontakt med fler företag? Det
        ordnar vi!
        <a href="mailto:hjalp@husguiden.nu">hjalp@husguiden.nu</a>
      </div>

      <div class="info-text"
        style="margin-top: 30px; font-size: 18px; font-weight: bold">
        <a href="https://byggtipset.com"
          target="_blank">
          <span>Utnyttja din personliga inloggning – gå in på BYGGTIPSET</span>
        </a>
      </div>
    </div>

    <div class="right-content">
      <a href="https://byggtipset.com"
        class="hidden-xs hidden-sm visible-md visible-lg">
        <div class="papers-container-wrapper clearfix">
          <div class="papers-container">
            <div class="papers-notice">
              <div class="papers-notice-title">Byggtipset!</div>
              <div class="papers-notice-text">
                Vi hjälper dig som fastighetsägare inför kommande
                investeringar! Fyll i dina planer så förser vi dig med
                värdefull information!
              </div>
            </div>
            <div style="position: absolute; top: 60px; left: 50px">
              <img class="papers-paper"
                style="
                  position: absolute;
                  transform: rotate(10deg);
                  z-index: 1;
                "
                :src="require('../../../assets/images/byggtipset/HG Address Page 2024-1.png')
                  "
                width="600" />
              <img class="papers-paper"
                style="
                  position: absolute;
                  transform: rotate(30deg) translate(20%, -20%);
                  z-index: 0;
                "
                :src="require('../../../assets/images/byggtipset/HG Address Page 2024-2.png')
                  "
                width="600" />
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
.clearfix:before,
.clearfix:after {
    content: ".";    
    display: block;    
    height: 0;    
    overflow: hidden; 
}
.clearfix:after { clear: both; }

.container {
  display: flex;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.papers-container-wrapper {
  position: relative;
  width: 100%;
  height: 600px;
  justify-content: center;
  align-items: center;
}

.papers-container {
  top: 10%;
  left: 20%;
  position: relative;
  width: 600px;
  height: 500px;

  @media only screen and (max-width: 992px) {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
}

.papers-notice {
  position: absolute;
  background-color: #026230;
  width: 200px;
  height: 200px;
  border-radius: 150px;
  top: 8px;
  left: 200px;
  z-index: 3;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;

  @media only screen and (max-width: 992px) {
    left: 150px;
  }
}

.papers-notice-title {
  font-size: 24pt;
  text-align: center;
  font-family: 'Cookie', cursive;
  font-weight: 400;
  font-style: normal;

  @media only screen and (max-width: 992px) {
    font-size: 18pt;
  }
}

.papers-notice-text {
  font-size: 9pt;
  text-align: center;
}

.papers-paper {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  width: 300px;
}

#login-form #password {
  border-left: 0;
}

.info-text-title {
  color: #273548;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
}

.info-text {
  margin-bottom: 10px;
  text-align: center;
}

.survey-start {
  position: relative;

  .folder-container {
    margin-top: 20px;

    .browse-folder {
      left: 75px;
    }
  }

  .go-to-survey {
    font-size: 24px;

    @media only screen and (max-width: 992px) {
      margin-top: 20px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      margin-left: 30%;
    }
  }
}

#main-survey-panel {
  &.has-error {
    border-color: #a94442;
  }

  .error-message {
    color: #a94442;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

#survey-alert-container {
  display: none;

  #survey-alert {
    width: 100%;
    text-align: center;
    margin-top: 10px;

    @media screen and (min-width: 992px) {
      width: 33%;
      margin: 0 auto;
      display: inline-block;
      margin-top: 0;
    }
  }
}

#survey-answer-btn {
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.planned-action-top-title {
  @media screen and (min-width: 1200px) {
    margin-left: 20px;
  }
}

#planned-action-titles,
#planned-action-options {
  display: inline-block;
}

.survey-table-container {
  margin-bottom: 20px;
  padding: 10px 0 10px 0;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.survey-table {
  margin-bottom: 0;

  thead tr th,
  tbody tr th,
  tbody tr td {
    border: none !important;
  }

  thead tr th,
  tbody tr td {
    text-align: center;
    vertical-align: middle;
    padding: 0 !important;
  }

  tbody>tr>th {
    padding: 0 !important;
    text-align: right;
  }

  @media screen and (max-width: 320px) {
    tbody tr {
      display: inline-block;
      width: 100% !important;
      vertical-align: top;
      padding-top: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    thead {
      display: none;
    }

    tbody tr th {
      display: block;
      text-align: left;
    }

    tbody tr {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      padding-top: 10px;
    }

    tbody tr td {
      display: block;
      text-align: left;
    }

    tbody td:after {
      content: attr(data-th);
      display: inline-block;
      text-align: left;
    }
  }

  thead tr th:first-child {
    @media screen and (min-width: 768px) and (max-width: 992px) {
      width: 38%;
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      width: 29%;
    }
  }
}

.row-eq-height {
  display: block;
  margin-bottom: 20px;

  @media only screen and (max-width: 992px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media only screen and (min-width: 992px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.left-col,
.center-col,
.right-col {
  border: 1px solid #ddd;
  border-radius: 2px;
  padding-bottom: 10px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 992px) {
    margin-left: 15px;
    margin-bottom: 10px;
  }
}

.right-col {
  margin-right: 15px;
}

.answer-block {
  padding-top: 10px;
  padding-right: 0;

  .choice label {
    font-weight: normal;
  }
}

.survey-title {
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;

  span.glyphicon-chevron-down,
  span.glyphicon-chevron-right {
    margin-left: -25px;
    float: right;
  }
}

#answer-type-text {
  margin-bottom: 10px;
}

#leisure-home-answer-type,
#home-answer-type,
#agriculture-answer-type {
  font-weight: bold;
}

.answer-response {
  background: #fff;
  color: #273548;
  text-align: center;
  padding: 10px;
  border: 1px solid #273548;
  border-radius: 2px;
  margin-top: 10px;
  position: fixed;
  z-index: 99999;
  width: 33%;
  left: 39%;

  @media only screen and (min-width: 0px) {
    width: 80%;
    left: 10%;
  }

  @media only screen and (min-width: 320px) {
    width: 80%;
    left: 10%;
  }

  @media only screen and (min-width: 580px) {
    width: 60%;
    left: 20%;
  }

  @media only screen and (min-width: 768px) {
    width: 50%;
    left: 25%;
  }

  @media only screen and (min-width: 992px) {
    width: 33%;
    left: 35%;
  }

  @media only screen and (min-width: 1200px) {
    width: 33%;
    left: 35%;
  }

  @media only screen and (min-width: 1500px) {
    width: 25%;
    left: 38%;
  }

  .close-response {
    float: right;

    &:hover {
      cursor: pointer;
    }
  }

  a {
    display: block;
    margin-top: 10px;
  }

  a.btn-hg {
    display: inline-block;
  }
}

.survey-answered {
  font-size: 24px;
  color: #273548;
}

#admin-search {
  #search-result {
    .btn-success.disabled {
      cursor: default;
      opacity: 1;
    }

    //margin-top: 20px;
  }
}
</style>
