<template>
  <div
    class="modal fade"
    :class="{'show': isShown}"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!displaysElement"
    :style="{display: (isShown || displaysElement) ? 'block': 'none'}"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-header-title-container">
            <h5 class="modal-title">{{title}}</h5>
            <div class="modal-subtitle">{{subtitle}}</div>
          </div>
          <button type="button" class="close" :aria-label="$t('Close')" @click="this.close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <component v-bind="this.componentProps" :is="this.bodyComponent"></component>
        </div>
        <div class="modal-footer">
          <component
            v-bind="this.componentProps"
            v-if="this.footerComponent"
            :is="this.footerComponent"
          ></component>
          <button v-else type="button" class="btn btn-secondary" @click="this.close">{{$t('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: null,
      subtitle: null,
      isShown: false,
      displaysElement: false,
      bodyComponent: null,
      footerComponent: null,
      componentProps: null,
      callback: null,
      backdropElement: null,
    };
  },
  methods: {
    open({ title, subtitle, bodyComponent, componentProps, footerComponent }) {
      this.displaysElement = true;
      this.title = title;
      this.subtitle = subtitle;
      this.bodyComponent = bodyComponent;
      this.footerComponent = footerComponent;
      this.componentProps = componentProps;

      return new Promise(resolve => {
        this.callback = resolve;
      });
    },
    close() {
      if (this.callback) {
        this.callback();
      }

      this.displaysElement = false;
      this.title = null;
      this.bodyComponent = null;
      this.footerComponent = null;
      this.componentProps = null;
      this.callback = null;
    },
    createBackdropElement() {
      const element = document.createElement('div');
      element.classList.add('modal-backdrop', 'fade');

      return element;
    },
  },
  watch: {
    displaysElement(currentValue) {
      document.body.classList.toggle('modal-open', currentValue);

      if (currentValue) {
        this.backdropElement = this.createBackdropElement();
        document.body.appendChild(this.backdropElement);

        setTimeout(() => {
          this.backdropElement.classList.add('show');
          this.isShown = true;
        }, 100);
      } else if (this.backdropElement) {
        this.backdropElement.classList.remove('show');

        setTimeout(() => {
          document.body.removeChild(this.backdropElement);
        }, 300);

        this.isShown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-dialog {
  max-width: 800px;
}

.modal-header {
  background: #273548;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-header .close {
  color: #fff;
  outline: none;
}

.modal-header-title-container {
}

.modal-subtitle {
  font-size: 12px;
}

.modal-content {
  border: none;
}
</style>