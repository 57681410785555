<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-8">
        <h4>HusGuiden</h4>
        <p>
          Husguiden är en folder som delas ut till husägare som precis bestämt sig för att förändra sitt boende. Personer i behov av tips och erbjudanden från lokala hantverkare, butiker och tjänsteföretag.
          <br />
          <br />Foldern skickas i ett adresserat kuvert till privatpersoner i en specifik kommun som nyss beslutat sig för att förändra sitt boende.
          <br />
          <br />Nyblivna husägare renoverar i genomsnitt för drygt 400.000 kr under de första två åren.
          <br />
          <br />Husägare som sökt bygglov för att renovera, bygga om, bygga till eller bygga ett helt nytt hus.
          <br />
          <br />Nyinflyttade i kommunen som kanske inte känner till utbudet på sin nya hemort.
        </p>

        <h4>Din roll</h4>
        <p>
          Som säljare på Husguden blir du en del av vårt försäljningsteam med fokus på ny kundsbearbetning och nyförsäljning.
          Du arbetar aktivt med att skapa nya affärsmöjligheter med telefonen som redskap.
          Till detta tilldelas du även ett eget geografiskt område där du på ett strukturerat sätt kontaktar hantverkare och butiker kommunvis och erbjuder dem en unik plats att presentera sitt företag inför nya husägare och de som ansökt om bygglov.
        </p>

        <h4>Vem är du?</h4>
        <p>
          Vi söker dig som har arbetserfarenhet inom försäljning. Vi tror att dina personliga egenskaper är avgörande för hur väl du kommer att lyckas. Vi tror därför att du som söker är tävlingsinriktad och både trivs och drivs av att arbeta mot mål och brinner för försäljning och service till kunder i ett mycket högt tempo. Stämmer detta in på dig, tveka inte att ansöka redan idag!
          Vi erbjuder ett roligt och stimulerande jobb med mycket goda förtjänstmöjligheter.
        </p>

        <h4>Arbetsort</h4>
        <p>Ljusdal</p>

        <h4>Tillträde</h4>
        <p>Omgående eller enligt överenskommelse.</p>

        <h4>Ansökan</h4>
        <p>Senast 14 februari 2019</p>

        <h4>Skicka ditt CV</h4>
        <p>Samt en kort personlig beskrivning av dig själv till fajko.hafizovic@husguiden.nu</p>
        <br />
        <br />
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="folder-container">
          <img :src="require('../../../assets/images/folder.png')" class="folder-img" />
          <img :src="require('../../../assets/images/elbutiken2.png')" class="folder-img" />
          <img :src="require('../../../assets/images/svensson.png')" class="folder-img" />
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/** Start **/

.folder-img {
  border: 1px solid #ccc;
  border-radius: 2px;
}

@media (min-width: 0px) {
  /*.start-container {
      padding-right: 15px;
      padding-left: 15px;
      margin-left: auto;
      margin-right: auto;
  }*/

  .start-about-text {
    text-align: center;
    /*margin: 5%;*/
  }

  .market-text-img {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .folder-container {
    width: 100%;
    text-align: center;
  }

  .folder-container img {
    /*width: 265px;*/
    margin: 0 auto;
  }

  .folder-container img:nth-child(2),
  .folder-container img:nth-child(3) {
    display: none;
  }

  .browse-folder {
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    color: #272525;
  }

  .browse-folder #browse-text {
    color: inherit;
  }

  .browse-folder #browse-icon {
    font-size: 30px;
    top: 5px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 992px) {
  .people-left-col {
    display: block;
  }

  .about-text,
  .info-text {
    text-align: left;
    margin-bottom: 10px;
  }

  .market-text-img {
    margin-top: 80px;
  }

  .folder-container {
    margin-top: 90px;
    -ms-transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .folder-container img.folder-img:nth-child(1) {
    display: block;
    z-index: 3;
    position: absolute;
  }

  .folder-container img.folder-img:nth-child(2) {
    display: block;
    z-index: 2;
    position: absolute;
    /*left: 32px;
    top: 20px;*/
    top: 18px;
    left: 20px;
  }

  .folder-container img.folder-img:nth-child(3) {
    display: block;
    z-index: 1;
    position: absolute;
    left: 50px;
    top: 10px;
  }

  .folder-container img.folder-img:nth-child(4) {
    display: block;
    z-index: 0;
    position: absolute;
    left: 80px;
    top: 18px;
  }

  .browse-folder {
    z-index: 999999;
    position: absolute;
    left: 140px;
    top: 360px;
    font-size: 24px;
    font-weight: bold;
    color: #272525;
  }

  .browse-folder #browse-text {
    color: inherit;
  }

  .browse-folder #browse-icon {
    font-size: 30px;
    margin-left: 5px;
  }

  .content-left {
    display: inline-block;
    margin-left: 10%;
  }

  .content-left .row {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .market-text-img {
    margin-top: 110px;
  }

  .folder-container {
    margin-top: 110px;
  }
}
</style>