<template>
  <tr class="expanded-list-item" :style="{height: `${height}px`}" :class="{expanded: isExpanded}">
    <td ref="container" class="expanded-list-item-content">
      <div
        class="expanded-list-item-content-container"
        :style="{width: `calc(${tableWidth}px - (0.75rem * 2))`}"
      >
        <component :is="expansionComponent" :item="prevItem" v-bind="expansionComponentProps"></component>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: [
    'isExpanded',
    'prevItem',
    'expansionComponent',
    'expansionComponentProps',
    'tableWidth',
  ],
  data() {
    return {
      height: 0,
    };
  },
  methods: {
    getContainerHeight() {
      const { height } = this.$refs.container.getBoundingClientRect();
      return height;
    },
  },
  watch: {
    isExpanded(currentValue) {
      if (currentValue) {
        this.height = this.getContainerHeight();
      } else {
        this.height = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.expanded-list-item {
  position: relative;
  height: 0px;
  overflow: hidden;
  transition: height 0.3s, opacity 0.3s;
  opacity: 0;
  pointer-events: none;
  // background-color: #ddd;
}

.expanded-list-item.expanded {
  opacity: 1;
  pointer-events: all;
}

.expanded-list-item-content {
  position: absolute;
  background-color: #ddd;
}

@media all and (-ms-high-contrast:none) {
  .expanded-list-item {
    visibility: hidden;
  }

  .expanded-list-item.expanded {
    visibility: visible;
  }
}
</style>
