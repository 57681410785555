import VueRouter from 'vue-router';
import Home from '../components/pages/Home';
import AboutHusguiden from '../components/pages/AboutHusguiden';
import Advertisment from '../components/pages/Advertisment';
import Reference from '../components/pages/Reference';
import BuildHint from '../components/pages/BuildHint';
import Contact from '../components/pages/Contact';
import Policy from '../components/pages/Policy';
import Film from '../components/pages/Film';
import JobAdvertisement from '../components/pages/JobAdvertisement';
import ProductionHistory from '../components/pages/ProductionHistory/index';
import Login from '../components/pages/Login';
import ProductionDetail from '../components/pages/ProductionDetail';
import ReviewSalesletter from '../components/pages/ReviewSalesletter';
import ReviewRequestSent from '../components/pages/ReviewRequestSent';
import FolderFlipbook from '../components/FolderFlipbook';

const defaultTitle = 'Husguiden';

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '',
      name: 'home',
      component: Home,
      meta: {
        title: 'Nå ut till rätt målgrupp i rätt tid',
      },
      data: {
        titleKey: 'Husguiden',
      },
    },
    {
      path: '/om-husguiden',
      name: 'om-husguiden',
      component: AboutHusguiden,
      data: {
        titleKey: 'om-husguiden',
      },
    },
    {
      path: '/flipbook',
      name: 'flipbook',
      component: FolderFlipbook,
      data: {
        titleKey: 'flipbook',
      },
      props: route => ({ query: route.query.q }),
    },
    {
      path: '/annonsera-i-husguiden',
      name: 'annonsera-i-husguiden',
      component: Advertisment,
      meta: {
        title: 'Annonsera lokal folder i alla kommuner',
      },
      data: {
        titleKey: 'annonsera-i-husguiden',
      },
    },
    {
      path: '/referenser',
      name: 'referenser',
      component: Reference,
      data: {
        titleKey: 'referenser',
      },
    },
    {
      path: '/husagare',
      name: 'husagare',
      component: BuildHint,
      data: {
        titleKey: 'tips',
      },
    },
    {
      path: '/kontakt',
      name: 'kontakt',
      component: Contact,
      data: {
        titleKey: 'tips',
      },
    },
    {
      path: '/policy',
      name: 'policy',
      component: Policy,
      data: {
        titleKey: 'tips',
      },
    },
    {
      path: '/film',
      name: 'film',
      component: Film,
      data: {
        titleKey: 'film',
      },
    },
    {
      path: '/jobbannons',
      name: 'jobbannons',
      component: JobAdvertisement,
      meta: {
        title: 'Jobb',
      },
      data: {
        titleKey: 'Säljare på Husguiden',
      },
    },
    {
      path: '/production-history',
      name: 'production-history',
      component: ProductionHistory,
      meta: {
        title: 'Mina Leveranser',
      },
      data: {
        titleKey: 'production-history',
      },
    },
    {
      path: '/production-detail',
      name: 'production-detail',
      component: ProductionDetail,
      meta: {
        title: 'Mina Leveranser',
      },
      data: {
        titleKey: 'production-history',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      data: {
        titleKey: 'Log in',
      },
    },
    {
      path: '/review-salesletter',
      name: 'review-salesletter',
      component: ReviewSalesletter,
      data: {
        titleKey: 'Se över säljbrevet',
      },
    },
    {
      path: '/review-request-sent',
      name: 'review-request-sent',
      component: ReviewRequestSent,
      data: {
        titleKey: 'Tack!',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${defaultTitle} | ${to.meta.title}`;
  } else {
    document.title = defaultTitle;
  }
  next();
});

export default router;
