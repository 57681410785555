<template>
  <div class="container">
    <div class="page-header">
      <h1>{{ $t('Review sales letter') }}</h1>
      <div class="page-description">
        {{ $t('Request a change or review your sales letter') }}
      </div>
    </div>
    <div class="form-group">
      <label for="subject-select">{{ $t('Subject') }}</label>
      <select
        id="subject-select"
        class="form-control"
        name="mail-subject"
        @change="handleSubjectChange"
      >
        <option
          v-for="key in Object.keys(subjects)"
          :key="key"
          :value="key"
        >
          {{ subjects[key] }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label
        class="with-sublabel"
        for="subject-select"
      >
        {{ $t('Profile') }}
      </label>
      <label class="sublabel">{{ $t('The profile that this message is about') }}</label>
      <select
        id="profile-select"
        v-model="form.profileKey"
        class="form-control"
        name="profile"
      >
        <option
          v-for="profileKey in Object.keys(profileOptions)"
          :key="profileKey"
          :value="profileKey"
        >
          {{ profileOptions[profileKey].name }}
        </option>
      </select>
    </div>
    <div
      v-if="form.subjectKey == 'attach-file'"
      class="form-group"
    >
      <input
        ref="fileInput"
        type="file"
        @change="handleFileChange"
      >
      <div
        v-if="form.file"
        class="remove-button-container"
      >
        <button
          class="btn btn-danger"
          @click="clearFileInput"
        >
          <font-awesome-icon icon="times" />
          {{ $t('Remove file') }}
        </button>
      </div>
    </div>
    <div class="form-group">
      <label for="body-text">{{ $t('Message') }}</label>
      <textarea
        id="body-text"
        v-model="form.body"
        name="mail-body"
        class="form-control"
        cols="30"
        rows="10"
      />
    </div>
    <button
      class="btn btn-husguiden"
      @click="sendRequest"
    >
      {{ $t('Send request') }}
    </button>
  </div>
</template>

<script>
import { sendCustomerMail } from "../../shared/customerMail";
import LazySpinner from '../../shared/LazySpinner';

export default {
  props: {
    profileConfigs: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const subjects = {
      'see-current': this.$t('We want to see the current sales letter'),
      'update-info': this.$t(
        'We have new contact info, logo or address info to update the sales letter with',
      ),
      campaign: this.$t(
        'We have a upcoming campaign and want to have it in Husguiden',
      ),
      add: this.$t('We want to add something'),
      'other-print': this.$t(
        'We are interested in sales letter, business cards, postcards for extended advertising',
      ),
      'attach-file': this.$t(
        'We are attaching a file to be used in our sales letter',
      ),
      'change-date': this.$t('We want to change a date in our sales letter'),
      'complete-municipalities': this.$t('We are interested in adding the following municipalities'),
      'new-services': this.$t('We are interested in the following additional services'),
    };


    return {
      subjects,
      form: {
        subjectKey: Object.keys(subjects)[0],
        body: '',
        file: undefined,
        profileKey: this.profileConfigs && Object.keys(this.profileConfigs)[0],
      },
    };
  },
  computed: {
    profileOptions() {
      const options = {
        ...this.profileConfigs,
        // all: {
        //   editionId: 0,
        //   profileId: 0,
        //   hidden: false,
        //   name: this.$t('(All)'),
        // },
      };

      Object.keys(options).forEach(key => {
        if (options[key].hidden) {
          delete options[key];
        }
      });

      return options;
    },
  },
  watch: {
    profileConfigs(currentValue, prevValue) {
      if (currentValue && !this.form.profileKey) {
        this.form.profileKey = this.profileConfigs && Object.keys(this.profileConfigs)[0];
      }
    },
  },
  methods: {
    handleSubjectChange(event) {
      this.form.subjectKey = event.target.value;
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        this.form.file = event.target.files[0];
      }
    },
    clearFileInput() {
      this.$refs.fileInput.value = '';
      this.form.file = undefined;
    },
    async sendRequest() {
      if (this.form.body.length < 1 && !this.form.file) {
        return;
      }

      const setSpinnerNotNeeded = LazySpinner.show();
      try {
        const profile = this.profileOptions[this.form.profileKey];
        const { data } = await sendCustomerMail(
          this.subjects[this.form.subjectKey],
          this.form.body,
          this.form.file,
          profile.profileId || 0,
          profile.editionId || 0,
        );

        if (data.success) {
          this.$router.push('/review-request-sent');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSpinnerNotNeeded();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-description {
  color: #888;
}

.page-header {
  margin-bottom: 32px;
}

.remove-button-container {
  margin-top: 8px;
}

.with-sublabel {
  margin-bottom: 0;
}

.sublabel {
  font-size: 12px;
  color: #888;
  display: block;
}
</style>
