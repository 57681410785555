import EventBus from '../shared/EventBus';

export default class AuthService {
  authUrl;

  // store the URL so we can redirect after logging in
  redirectUrl;

  dataAccess;

  userService;

  tokenService;

  constructor(dataAccess, userService, tokenService) {
    this.dataAccess = dataAccess;
    this.userService = userService;
    this.tokenService = tokenService;

    this.authUrl = `${this.dataAccess.API}token`;
  }

  getLoggedInUser() {
    const tokenPayload = this.tokenService.decodeToken();

    return tokenPayload && this.userService.getUser(tokenPayload && tokenPayload.UserId);
  }

  login(username, password) {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('username', username);
    urlSearchParams.append('password', password);

    // Converts params to format: ?username=x&password=y
    const data = urlSearchParams.toString();
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return this.dataAccess.post(this.authUrl, data, config).then(auth => {
      if (auth.access_token) {
        this.tokenService.setTokenCookie(auth.access_token);

        const tokenPayload = this.tokenService.decodeToken();
        EventBus.$emit('login', tokenPayload && tokenPayload.UserId);
      }

      return auth;
    });
  }

  get isLoggedIn() {
    const isTokenExpired = this.tokenService.isTokenExpired();

    return !isTokenExpired;
  }

  refreshToken() {
    return this.dataAccess.getResponse(`${this.authUrl}/refresh`).then(({ data }) => {
      const auth = data.data[0];

      if (auth.access_token) {
        this.tokenService.setTokenCookie(auth.access_token);
        const tokenPayload = this.tokenService.decodeToken();
        EventBus.$emit('login', tokenPayload && tokenPayload.UserId);
      }
    });
  }

  logout() {
    this.tokenService.logout();
    EventBus.$emit('logout');
  }
}
