<template>
  <div class="paginator">
    <select-with-description
      ref="profileCategorySelect"
      name="profileCategory"
      :description="label"
      :items="pageSizes"
      :activeItem="itemsPerPage"
      :inline-description="true"
      :selectKey="item => item"
      :selectLabel="item => item"
      :onChange="onRequestChangePageSize"
      class="d-inline-block button-sibling-right"
    ></select-with-description>
    <label class="button-sibling-right">{{`${(pageIndex + 1)} / ${pageCount}`}}</label>
    <div class="d-inline-block btn-group" role="group" :aria-label="label">
      <button
        :disabled="pageCount <= 1 || pageIndex == 0"
        type="button"
        class="btn btn-secondary"
        @click="handleRequestVisitPrevPage"
      >
        <i class="cui-arrow-left"></i>
      </button>
      <button
        :disabled="pageCount <= 1 || pageIndex == (pageCount - 1)"
        type="button"
        class="btn btn-secondary"
        @click="handleRequestVisitNextPage"
      >
        <i class="cui-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import SelectWithDescription from './SelectWithDescription';

export default {
  data() {
    return {
      pageCount: 1,
    };
  },
  props: {
    label: {
      type: String,
      default: function() {
        return this.$t('Objects per page:');
      },
    },
    itemCount: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    pageIndex: {
      type: Number,
      default: 0,
    },
    pageSizes: {
      type: Array,
      default: function() {
        return [5, 10, 25, 100];
      },
    },
    onRequestChangePage: {
      type: Function,
    },
    onRequestChangePageSize: {
      type: Function,
    },
  },
  components: {
    SelectWithDescription,
  },
  methods: {
    handleRequestVisitNextPage: function() {
      this.onRequestChangePage(this.pageIndex + 1);
    },
    handleRequestVisitPrevPage: function() {
      this.onRequestChangePage(this.pageIndex - 1);
    },
    getPageCount: function(itemCount, itemsPerPage) {
      return Math.ceil(itemCount / itemsPerPage);
    },
  },
  watch: {
    itemCount: function(currentValue) {
      this.pageCount = this.getPageCount(currentValue, this.itemsPerPage);
    },
    itemsPerPage: function(currentValue) {
      this.pageCount = this.getPageCount(this.itemCount, currentValue);
    },
  },
  mounted() {
    this.pageCount = this.getPageCount(this.itemCount, this.itemsPerPage);
  },
};
</script>

<style lang="scss" scoped>
.paginator {
}
</style>