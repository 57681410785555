const parser = () => {
  const unitMultipliers = {
    kr: 1,
    mkr: 1000000,
    mdkr: 1000000000,
  };
  const toParseableNumber = text => text.replace(/[^\d.,]*/g, '');
  const getLastSegment = array => array[array.length - 1];
  const parse = text => {
    const segments = text.split(' ');
    if (segments.length < 2) return parseFloat(segments[0]);

    const unit = getLastSegment(segments).toLowerCase();
    segments.pop();

    const value = parseFloat(toParseableNumber(segments.join('')));

    if (unitMultipliers[unit] !== undefined) {
      return value * parseFloat(unitMultipliers[unit]);
    }

    return null;
  };

  return {
    parse,
  };
};

export default parser;
