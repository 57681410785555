<template>
  <div class="container">
    <p>
      Husguiden delas ut till husägare som precis bestämt sig för att förändra sitt boende. Personer i behov av tips och erbjudanden från lokala hantverkare, butiker och tjänsteföretag!
    </p>
    <p>Foldern skickas i ett adresserat kuvert till privatpersoner i en specifik kommun som nyss beslutat sig för att förändra sitt boende:</p>
    <ul>
      <li>Nya husägare. Nyblivna husägare renoverar i genomsnitt för drygt 400.000 kr under de första två åren.</li>
      <li>Husägare som sökt bygglov för att renovera, bygga om, bygga till eller bygga helt nytt.</li>
      <li>Nyinflyttade i kommunen som kanske inte känner till utbudet på sin nya hemort.</li>
    </ul>

    <div style="text-align: center;">
      <iframe width="860" height="515" src="https://www.youtube.com/embed/roMhXZML0_c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

  </div>
</template>

<script>
import { mixin as removeWhitespaceMixin } from 'vue-remove-whitespace';

export default {
  mixins: [removeWhitespaceMixin]
};
</script>
