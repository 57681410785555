<template>
  <div>
    <div class="container logo-container">
      <img class="logo" src="../../../assets/images/logo.svg" />
    </div>
    <nav class="navbar navbar-expand-lg navbar-dark navbar-hg">
      <div class="navbar-content">
        <button class="navbar-toggler collapsed" type="button" @click="toggleMobileMenu">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbar" class="navbar-collapse collapse" :class="{show: mobileMenuIsExpanded}">
          <ul class="navbar-nav mr-auto top-menu">
            <li class="nav-item">
              <router-link class="nav-link" to="/">{{$t("Start")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/om-husguiden">{{$t('About Husguiden')}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/annonsera-i-husguiden">{{$t("Adds Husguiden")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/referenser">{{$t("Reference")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/husagare">{{$t("Byggtipset for home owner")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/kontakt">{{$t("Contact")}}</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <router-link class="nav-link" to="/production-history">{{$t("My delivery")}}</router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <router-link class="nav-link" to="/review-salesletter">{{$t("Review sales letter")}}</router-link>
            </li>
          </ul>
          <ul class="navbar-nav top-menu">
            <li class="nav-item" v-if="!isLoggedIn">
              <router-link class="nav-link" to="/login">
                <i class="far fa-user" aria-hidden="true"></i>
                {{$t("Login")}}
              </router-link>
            </li>

            <li
              ref="profileDropdown"
              v-if="isLoggedIn && user"
              class="nav-item profile-dropdown no-select"
            >
              <a
                @click.self="toggleDropdown"
                class="nav-link profile-dropdown-toggle active"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="cui-user" aria-hidden="true"></i>
                {{user.fullname}}
              </a>
              <div
                class="profile-dropdown-menu dropdown-menu dropdown-menu-sw"
                aria-labelledby="navbarDropdownMenuLink"
                :class="{show: dropdownIsExpanded}"
              >
                <div class="profile-dropdown-list" v-if="isLoggedIn">
                  <h6 class="profile-dropdown-header">{{user.firstname}}</h6>
                  <div v-if="user.myProfiles.length > 0" class="dropdown-divider"></div>
                  <ul class="dropdown-profile-list">
                    <li v-for="profile in user.myProfiles" :key="`${profile.id}${profile.name}`">{{profile.name}}</li>
                  </ul>
                  <div class="dropdown-divider"></div>
                </div>
                <profile-dropdown-menu-item
                  :onRequestCloseMenu="() => toggleDropdown(false)"
                  :onClick="handleRequestLogout"
                  :content="$t('Log out')"
                ></profile-dropdown-menu-item>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { authService } from '../../shared/services';
import ProfileDropdownMenuItem from './ProfileDropdownMenuItem';

export default {
  name: 'nav-menu',
  props: ['user', 'isLoggedIn'],
  components: {
    ProfileDropdownMenuItem,
  },
  created() {
    addEventListener('click', this.globalClickHandler);
  },
  mounted() {},
  beforeDestroy() {
    removeEventListener('click', this.globalClickHandler);
  },
  data() {
    return {
      isExpanded: false,
      mobileMenuIsExpanded: false,
      dropdownIsExpanded: false,
      profile: {
        id: 0,
      },
    };
  },
  methods: {
    collapse: function() {
      this.isExpanded = false;
    },
    toggle: function() {
      this.isExpanded = !this.isExpanded;
    },
    toggleMobileMenu: function() {
      this.mobileMenuIsExpanded = !this.mobileMenuIsExpanded;
    },
    toggleDropdown: function(state) {
      if (typeof state === 'boolean') {
        this.dropdownIsExpanded = state;
        return;
      }

      this.dropdownIsExpanded = !this.dropdownIsExpanded;
    },
    globalClickHandler: function(event) {
      if (!this.$refs.profileDropdown) return;

      const shouldCloseProfileMenu =
        !this.$refs.profileDropdown.contains(event.target) ||
        event.target.classList.contains('profile-dropdown-item');

      if (shouldCloseProfileMenu) {
        this.toggleDropdown(false);
      }
    },
    handleRequestLogout() {
      authService.logout();
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC&display=swap');
@import '../../../assets/css/_variables.scss';

.logo-container {
  width: 100%;
  padding: 0;
}

nav {
  margin-bottom: 15px;
  // font-family: 'Alegreya Sans SC', Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

li.dropdown a {
  cursor: pointer;
}

.navbar-dark.navbar-hg .navbar-nav .nav-link {
  color: #fff;
  padding: .25rem;
  padding-left: .6rem;
  padding-right: .6rem;
  margin: .25rem;
}

.navbar-hg .nav-link:hover {
  color: #fff;
}

// .nav-link:hover {
//   font-weight: bold;
// }

.nav-item {
  position: relative;
}

.nav-link::before {
  content: '';
  position: absolute;
  width: 0%;
  opacity: 0;
  height: 3px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15%;
  background-color: #fff;
  transition: width 0.3s, opacity 0.3s;
  border-radius: 4px;
}

.nav-link.active:before {
  content: none;
}

.nav-item:hover .nav-link::before {
  width: 90%;
  opacity: 1;
}

.navbar-nav .nav-item .nav-link {
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;
}

.navbar-nav .nav-item .nav-link.active {
  background-color: #fff;
  color: #273548;
}

.logo {
  padding: 16px 0 ;
  width: 300px;
}

@media screen and (max-width: 500px) {
  .logo {
    padding: 10%;
    width: 90%;
    height: 90%;
  }
}

@media screen and (min-width: 992px) {
  .navbar-hg {
    display: flex;
    justify-content: center;
  }

  nav {
    font-size: 1.05vw;
  }
}

@media screen and (max-width: 992px) {
    .logo-container {
      display: flex;
      justify-content: center;
    }

    .navbar-content {
      width: 100%;
    }

    .navbar-collapse {
      margin-top: 8px;
    }

    .nav-link {
      left: 0;
      -webkit-transform: none;
      transform: none;
    }
}

.profile-dropdown {
  position: relative;
}

.profile-dropdown-list {
  padding-left: 16px;
  padding-right: 16px;
}

.profile-dropdown-list ul {
  padding-inline-start: 24px;
}

.profile-dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.profile-dropdown-header {
  text-align: center;
  margin-top: 0.5rem;
}

.dropdown-profile-list {
  max-height: 256px;
  overflow-y: scroll;
}

.dropdown-menu {
  font-weight: 600;
}

.profile-dropdown-item {
  padding: 8px;
  margin: 16px;
  border-radius: 4px;
  background-color: #273548;
  color: #fff;
  font-weight: 600;
}

.profile-dropdown-item:hover {
  background-color: #273548;
  cursor: pointer;
}

.profile-dropdown-item:active {
  background-color: #273548;
}
</style>
