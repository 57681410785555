import Axios from 'axios';
import { dataAccessHelperService } from './services';

const sendCustomerMail = (subject, body, file, profileId, editionId) => {
  dataAccessHelperService.redirectToLoginIfTokenIsExpired();

  const requestConfig = dataAccessHelperService.getRequestConfig();
  const formData = new FormData();
  formData.append('subject', subject);
  formData.append('body', body);
  formData.append('profileId', profileId);
  formData.append('editionId', editionId);
  if (file) formData.append('file', file);

  return Axios.post(
    `${dataAccessHelperService.API}customer-request`,
    formData,
    requestConfig,
  );
};

export { sendCustomerMail };
