<template>
  <flipbook class="flipbook"
            :singlePage="true"
            :zooms="null"
            v-slot="flipbook"
            ref="flipbook"
            :pages="pages">
    <div class="action-bar">
      <button class="btn btn-secondary"
              :class="{ disabled: !flipbook.canFlipLeft }"
              @click="flipbook.flipLeft">
        {{ "<" }}
      </button>
      <span class="page-num ml-3 mr-3">
        Sida {{ flipbook.page }} av {{ flipbook.numPages }}
      </span>
      <button class="btn btn-secondary"
              :class="{ disabled: !flipbook.canFlipRight }"
              @click="flipbook.flipRight">
        {{ ">" }}
      </button>
    </div>
  </flipbook>
</template>

<script>
  import Flipbook from 'flipbook-vue';

  export default {
    components: {
      Flipbook
    },
    props: ['query'],
    data: function () {
      return {
        pages: []
      }
    },
    mounted: function () {
      const pageSets = {
        alvesta: [
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0001.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0002.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0003.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0004.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0005.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0006.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0007.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0008.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0009.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0010.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0011.jpg'),
          require('../../assets/images/examples/flip/Alvesta_0764_low/Alvesta_0764_low_page-0012.jpg'),
        ],
        ekero: [
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0001.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0002.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0003.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0004.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0005.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0006.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0007.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0008.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0009.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0010.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0011.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0012.jpg'),
          require('../../assets/images/examples/flip/Ekerö_0125_low/Ekerö_0125_low_page-0013.jpg'),
        ],
        katrineholm: [
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0001.jpg'),
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0002.jpg'),
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0003.jpg'),
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0004.jpg'),
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0005.jpg'),
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0006.jpg'),
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0007.jpg'),
          require('../../assets/images/examples/flip/Katrineholm_0483/Katrineholm_0483_page-0008.jpg'),
        ]
      }

      const validFolders = ["alvesta", "ekero", "katrineholm"];
      const set = !validFolders.includes(this.query) ? "alvesta" : this.query;
      this.pages = pageSets[set];
    }
  };
</script>

<style lang="scss" scoped>
  .flipbook {
    width: 99vw;
    height: 90vh;
    margin-bottom: 100px;
  }

  .action-bar {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
