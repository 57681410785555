<template>
  <table class="hg-table" ref="table">
    <thead>
      <tr>
        <th
          scope="col"
          v-for="(columnName, index) in columnNames"
          :class="{'buttonlike': sortingFunctions && sortingFunctions[index]}"
          :key="columnName"
          @click="handleOnRequestSort(index)"
        >
          <div class="expandable-table-head-col">
            {{columnName}}
            <div class="icon-container">
              <i
                :class="{'sort-mode-icon': true, 'sort-mode-icon-shown': index === sortedKey && sortingMode === SORTING_MODE_ASCENDING}"
                class="cui-arrow-top"
              ></i>
              <i
                :class="{'sort-mode-icon': true, 'sort-mode-icon-shown': index === sortedKey && sortingMode === SORTING_MODE_DESCENDING}"
                class="cui-arrow-bottom"
              ></i>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <component
        v-for="(row, index) in rows"
        :is="determineListItemComponent(row.type)"
        :key="row.key"
        :item="row.value"
        :prevItem="rows[index - 1] && rows[index - 1].value"
        @click.native="handleListItemOnClick($event, row)"
        :isExpanded="expandedItemKey === row.key"
        :isExpandedItem="expandedItemKey === (rows[index + 1] && rows[index + 1].key)"
        :tableItemComponent="tableItemComponent"
        :expansionComponent="expansionComponent"
        :tableItemComponentProps="tableItemComponentProps"
        :expansionComponentProps="expansionComponentProps"
        :tableWidth="tableWidth"
      ></component>
    </tbody>
  </table>
</template>

<script>
import TableItem from './TableItem';
import TableItemExpansion from './TableItemExpansion';

const SORTING_MODE_OFF = 0;
const SORTING_MODE_ASCENDING = 1;
const SORTING_MODE_DESCENDING = 2;

export default {
  props: {
    expandsItems: {
      type: Boolean,
      required: false,
    },
    columnNames: {
      type: Array,
      required: true,
    },
    values: {
      type: Array,
      required: false,
    },
    tableItemComponent: {
      type: Object,
      required: true,
    },
    expansionComponent: {
      type: Object,
      required: false,
    },
    getKey: {
      type: Function,
      required: true,
    },
    tableItemComponentProps: {
      type: Object,
      required: false,
    },
    expansionComponentProps: {
      type: Object,
      required: false,
    },
    sortingFunctions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      expandedItemKey: null,
      sortedKey: -1,
      sortingMode: SORTING_MODE_OFF,
      sortedValues: [],
      SORTING_MODE_OFF,
      SORTING_MODE_ASCENDING,
      SORTING_MODE_DESCENDING,
    };
  },
  computed: {
    rows() {
      const rows = (this.sortedValues || []).reduce((newList, value) => {
        const key = this.getKey(value);
        const useExpansionComponent = !!this.expansionComponent;
        const items = [
          {
            type: 'tableItem',
            key,
            value: value,
          },
        ];

        if (useExpansionComponent)
          items.push({
            type: 'expansion',
            key: `exp${key}`,
          });

        return newList.concat(...items);
      }, []);

      if (this.sortByKey !== undefined) {
        return rows.sort(this.sortingFunction);
      }

      return rows;
    },
    tableWidth() {
      if (this.$refs.table) {
        return this.$refs.table.getBoundingClientRect().width;
      }
    },
  },
  watch: {
    values: {
      immediate: true,
      handler(currentValue) {
        this.sortedValues = this.sortValues(
          currentValue,
          this.sortingMode,
          this.sortedKey
        );
      },
    },
    sortingMode(currentValue) {
      this.sortedValues = this.sortValues(
        this.values,
        currentValue,
        this.sortedKey
      );
    },
    sortedKey(currentValue) {
      this.sortedValues = this.sortValues(
        this.values,
        this.sortingMode,
        currentValue
      );
    },
  },
  methods: {
    determineListItemComponent(itemType) {
      if (itemType === 'tableItem') {
        return TableItem;
      } else if (itemType === 'expansion') {
        return TableItemExpansion;
      }

      return TableItem;
    },
    handleRequestExpandItem(type, key) {
      if (type !== 'tableItem') return;

      if (this.expandedItemKey === `exp${key}`) {
        this.expandedItemKey = null;
      } else {
        this.expandedItemKey = `exp${key}`;
      }
    },
    handleListItemOnClick(event, row) {
      if (event.target.tagName === 'TD') {
        this.handleRequestExpandItem(row.type, row.key);
      }
    },
    sortValues(currentValues, sortingMode, sortedKey) {
      let newList = currentValues.slice();

      if (sortingMode !== SORTING_MODE_OFF && this.sortingFunctions) {
        if (sortedKey > -1 && this.sortingFunctions[sortedKey]) {
          newList.sort(this.sortingFunctions[sortedKey]);
        }

        if (sortingMode === SORTING_MODE_ASCENDING) newList = newList.reverse();
      }

      return newList;
    },
    handleOnRequestSort(index) {
      if (!(this.sortingFunctions && this.sortingFunctions[index])) return;
      if (this.sortedKey === index) {
        this.sortingMode = (this.sortingMode + 1) % 3;
        if (this.sortingMode === SORTING_MODE_OFF) this.sortedKey = -1;
      } else {
        this.sortedKey = index;
        this.sortingMode = SORTING_MODE_ASCENDING;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  position: relative;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.sort-mode-icon {
  position: absolute;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.1s, transform 0.3s;
}

.sort-mode-icon-shown {
  opacity: 1;
  transform: translateY(0);
}
</style>