var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.answered.length > 0 && !_vm.isLoading)?_c('expandable-table',{attrs:{"column-names":[
      _vm.$t('Name'),
      _vm.$t('Municiple'),
      _vm.$t('Description'),
      _vm.$t('Receipt Date'),
      _vm.$t('Planned investment'),
    ],"sorting-functions":[_vm.nameSort, _vm.municipleSort, _vm.descriptionSort, _vm.updateDateSort],"values":_vm.answered,"get-key":value => value.cooId,"table-item-component":_vm.SearchResultItem,"expansion-component":_vm.SearchResultItemExpansion,"table-item-component-props":{onRequestOpenDialog: _vm.handleRequestOpenDialog, isAnswer: true},"expansion-component-props":{onRequestOpenDialog: _vm.handleRequestOpenDialog}}}):_vm._e(),(_vm.answered.length < 1 && !_vm.isLoading)?_c('div',{staticClass:"status-message"},[_vm._v(" "+_vm._s(_vm.$t('There are no answers from this area'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }