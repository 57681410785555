<template>
  <div>
    <table class="hg-table small">
      <thead>
        <tr>
          <th>{{$t('Type')}}</th>
          <th>{{$t('Telephone')}}</th>
          <th>{{$t('Build location')}}</th>
          <th>{{$t('Municiple')}}</th>
          <th>{{$t('Address')}}</th>
          <th>{{$t('Value')}}</th>
          <th>{{$t('Description')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{item.typ}}</td>
          <td>{{item.phone}}</td>
          <td>{{item.buildLocation}}</td>
          <td>{{item.municiple}}</td>
          <td>{{[item.address, item.postAddress, item.town].filter(a => a).join(', ')}}</td>
          <td>{{item.value}}</td>
          <td>{{item.description}}</td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-husguiden" v-if="item.hasSurveyAnswer" @click="onRequestOpenDialog(item)">{{$t("Open")}}</button>
  </div>
</template>

<script>
export default {
  props: ['item', 'onRequestOpenDialog'],
};
</script>

<style lang="scss" scoped>
</style>