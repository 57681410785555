/* globals process */
import axios from 'axios';

export default class DataAccessHelperService {
  API = process.env.VUE_APP_API_URL || '/api/';

  router;

  tokenService;

  constructor(router, tokenService) {
    this.router = router;
    this.tokenService = tokenService;
  }

  get(url) {
    this.redirectToLoginIfTokenIsExpired();

    const requestConfig = this.getRequestConfig();

    // TODO: Implement simple error handling
    return axios.get(url, requestConfig);
  }

  static getTest(url) {
    return axios.get(url);
  }

  getResponse(url) {
    this.redirectToLoginIfTokenIsExpired();

    const requestConfig = this.getRequestConfig();
    // TODO: Implement simple error handlin
    return axios.get(url, requestConfig);
  }

  getResponseNotLogin(url) {
    const requestConfig = this.getRequestConfig();
    // TODO: Implement simple error handling
    return axios.get(url, requestConfig);
  }

  redirectToLoginIfTokenIsExpired() {
    if (this.tokenService.isTokenExpired()) {
      this.router.push('/login');
    }
  }

  getRequestConfig() {
    const headers = {
      Authorization: `Bearer ${this.tokenService.getTokenCookie()}`,
    };

    return { headers };
  }

  post(url, data, config) {
    return axios({ method: 'POST', url, data, config }).then(response => response.data);
  }
}
