<template>
  <div class="container" style="max-width: 1640px;">
    <div class="title-bar">
      <h3 class="back-button">
        <a class="buttonlike" @click="$router.go(-1)" style="color: black;">
          <i class="cui-arrow-left d-inline button-sibling-right"></i>
          <div class="d-inline">Tillbaka</div>
        </a>
      </h3>
      <h3 class="page-title">
        <div v-if="$route.params.pageTitle" class="d-inline">Utskick - {{$route.params.pageTitle}}</div>
      </h3>
      <button id="download" class="btn btn-husguiden" v-on:click="download">
        <font-awesome-icon icon="file-download" />Ladda ner
      </button>
    </div>
    <div>
      <expandable-table
        v-if="!isLoading"
        :columnNames="[
          $t('Type'),
          $t('Name'),
          $t('Address'),
          $t('Post address'),
          $t('Phone'),
          $t('Location'),
          $t('Municiple'),
          $t('Value'),
          $t('Description'),
        ]"
        :sortingFunctions="[typeSort, nameSort, addressSort, postCodeSort, phoneNumberSort, locationSort, municipleSort, valueSort, descriptionSort]"
        :values="this.detailList"
        :getKey="value => value.cooId"
        :tableItemComponent="TableItem"
        :expansionComponent="TableItemExpansion"
        :tableItemComponentProps="{onRequestOpenDialog: handleRequestOpenDialog}"
        :expansionComponentProps="{onRequestOpenDialog: handleRequestOpenDialog}"
      ></expandable-table>
    </div>
  </div>
</template>

<script>
import { productionDetailService, authService } from '../../../shared/services';
import LazySpinner from '../../../shared/LazySpinner';
import ExpandableTable from '@/components/ExpandableTable';
import TableItem from './TableItem';
import TableItemExpansion from './TableItemExpansion';
import DetailDialogBody from '../../DetailDialogBody';
import sortingFunctions from '../../../shared/sorting';

export default {
  props: ['showModal', 'profileConfigs', 'activeProfileConfigId'],
  components: {
    ExpandableTable,
  },
  data() {
    return {
      label: '',
      detailList: [],
      isLoading: false,
      TableItem,
      TableItemExpansion,
      ...sortingFunctions,
    };
  },
  computed: {
    editionId() {
      if (
        this.profileConfigs &&
        this.profileConfigs[this.activeProfileConfigId]
      ) {
        return this.profileConfigs[this.activeProfileConfigId].editionId;
      }

      return null;
    },
  },
  methods: {
    handleRequestOpenDialog(item) {
      if (item.cooId) {
        const setSpinnerNotNeeded = LazySpinner.show();
        productionDetailService
          .getFollowUp(item.cooId)
          .then(({ data }) => {
            const plans = data.data[0];
            this.showModal(this.$t('Planned investment'), DetailDialogBody, {
              plans,
            });
          })
          .finally(setSpinnerNotNeeded);
      }
    },
    download(event) {
      const setSpinnerNotNeeded = LazySpinner.show();
      this.isLoading = true;
      const dispatchId = this.$route.query.dispatch_id;
      const editionId = this.editionId;

      productionDetailService
        .downloadProductionDetail(dispatchId, editionId)
        .then(response => {
          const file = response.data;

          const linkSource = `data:${file.contentType};base64,${file.content}`;
          const downloadLink = document.createElement("a");
          const fileName = file.name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;

          downloadLink.click();
          URL.revokeObjectURL(downloadLink.href);
        })
        .finally(() => {
          setSpinnerNotNeeded();
          this.isLoading = false;
        });
      },
  },
  created() {
    if (!authService.isLoggedIn) {
      this.$router.push('/login');
      return;
    }

    const setSpinnerNotNeeded = LazySpinner.show();
    this.isLoading = true;
    const dispatchId = this.$route.query.dispatch_id;
    const editionId = this.editionId;

    let promise = editionId !== null 
      ? productionDetailService.getProductionDetailByEditionId(editionId, dispatchId)
      : productionDetailService.getProductionDetail(dispatchId);

    promise.then(({ data }) => {
      this.detailList = data.data;
    })
    .finally(() => {
      setSpinnerNotNeeded();
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

.title-bar {
  display: flex;
  padding: 16px;
}

.back-button {
  flex: 0.25;
}

.page-title {
  flex: 0.5;
  text-align: center;
}

#download.btn {
  text-transform: uppercase;
  font-size: 18px;
  padding: 0 10px;
}

#download svg {
  margin-right: 8px;
}
</style>
