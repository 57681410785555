<template>
  <div>
    <div class="container full-height">
      <div class="centered-content">
        <h1>{{ $t('Thank you!') }}</h1>
        <div class="page-description">
          {{ $t('Thank you for your message') }}
        </div>
        <div class="padded-content">
          <p>{{ $t('Have more ideas?') }}</p>
          <button
            class="btn btn-husguiden"
            @click="$router.push('/review-salesletter')"
          >
            {{ $t('Send another request') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.centered-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  height: 100%;
}

.padded-content {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: center;
}
</style>
