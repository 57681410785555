export default class CookieService {
  /**
   * @param name Cookie name
   * @returns {boolean}
   */
  check(name) {
    name = encodeURIComponent(name);

    const regExp = this.getCookieRegExp(name);
    const exists = regExp.test(document.cookie);

    return exists;
  }

  /**
   * @param name Cookie name
   * @returns {any}
   */
  get(name) {
    if (this.check(name)) {
      name = encodeURIComponent(name);

      const regExp = this.getCookieRegExp(name);
      const result = regExp.exec(document.cookie);

      return decodeURIComponent(result[1]);
    }
    return '';
  }

  /**
   * @returns {}
   */
  getAll() {
    const cookies = {};

    if (document.cookie && document.cookie !== '') {
      const split = document.cookie.split(';');

      for (let i = 0; i < split.length; i += 1) {
        const currentCookie = split[i].split('=');

        currentCookie[0] = currentCookie[0].replace(/^ /, '');
        cookies[decodeURIComponent(currentCookie[0])] = decodeURIComponent(currentCookie[1]);
      }
    }

    return cookies;
  }

  /**
   * @param name     Cookie name
   * @param value    Cookie value
   * @param expires  Number of days until the cookies expires or an actual `Date`
   * @param path     Cookie path
   * @param domain   Cookie domain
   * @param secure   Secure flag
   * @param sameSite OWASP samesite token `Lax` or `Strict`
   */
  set(name, value, expires, path, domain, secure, sameSite) {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

    if (expires) {
      if (typeof expires === 'number') {
        const dateExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);

        cookieString += `expires=${dateExpires.toUTCString()};`;
      } else {
        cookieString += `expires=${expires.toUTCString()};`;
      }
    }

    if (path) {
      cookieString += `path=${path};`;
    }

    if (domain) {
      cookieString += `domain=${domain};`;
    }

    if (secure) {
      cookieString += 'secure;';
    }

    if (sameSite) {
      cookieString += `sameSite=${sameSite};`;
    }

    document.cookie = cookieString;
  }

  /**
   * @param name   Cookie name
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  delete(name, path, domain) {
    this.set(name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain);
  }

  /**
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  deleteAll(path, domain) {
    const cookies = this.getAll();

    for (const cookieName in cookies) {
      if (cookies.hasOwnProperty(cookieName)) {
        this.delete(cookieName, path, domain);
      }
    }
  }

  /**
   * @param name Cookie name
   * @returns {RegExp}
   */
  getCookieRegExp(name) {
    const escapedName = name.replace(/([[]{}()|=;+?,.*^$])/gi, '\\$1');

    return new RegExp(`(?:^${escapedName}|;\\s*${escapedName})=(.*?)(?:;|$)`, 'g');
  }
}
