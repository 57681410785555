<template>
  <div class="row">
    <div class="col-xs-12 col-md-8">
      <div v-if="plans.planedWithIn.length > 0" class="construction-event-list">
        <div
          v-for="within of plans.planedWithIn"
          :key="`${within.type}${within.month}${within.message}`"
          class="construction-event"
        >
          <div class="construction-event-title">{{within.type}}</div>
          <div class="construction-event-timing-description">start inom {{within.month}} mån</div>
          <div class="construction-event-description">{{within.message}}</div>
        </div>
      </div>

      <div>
        <strong>Våra planer gäller</strong>
        <ul class="plan-list">
          <li v-for="building of plans.ourPlansBuilding" :key="building">{{building}}</li>
        </ul>
      </div>

      <p
        v-if="plans.categoryPlans.length > 0"
        class="planned-actions-title"
      >Planerade åtgärder inom respektive område</p>

      <div
        class="planned-action-category"
        v-for="category of plans.categoryPlans"
        :key="category.category"
      >
        <div class="planned-action-category-content">
          <div class="planned-action-category-title">{{category.category}}</div>
          <div
            class="planned-action"
            v-for="plan of category.plans"
            :key="plan.id"
          >{{plan.planedAction}}</div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4 person-details">
      <div class="created-by">{{$t('Created by')}}</div>
      <div>{{plans.contactPerson.fullName}}</div>
      <div>{{plans.contactPerson.adress}} {{plans.contactPerson.zipCode}} {{plans.contactPerson.town}}</div>
      <div>{{plans.contactPerson.email}}</div>
      <div>{{plans.contactPerson.phone}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['plans'],
};
</script>

<style lang="scss" scoped>
.construction-event-list {
  display: inline-block;
  margin-bottom: 16px;
}

.construction-event {
  display: inline-table;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  min-height: 100px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.construction-event-title {
  font-weight: bold;
}

.construction-event-timing-description {
  font-size: 15px;
}

.construction-event-description {
  font-size: 14px;
}

.created-by {
  font-weight: bold;
}

.plan-list {
  padding-left: 32px;
}

.planned-actions-title {
  padding-top: 8px;
  font-size: 18px;
  font-weight: bold;
}

.planned-actions-list {
}

.planned-action-category {
  display: inline-block;
  flex-direction: column;
  padding: 10px;
  width: 50%;
}

.planned-action-category-content {
  display: flex;
  flex-direction: column;
  min-height: 100px;
}

.planned-action-category-title {
  font-weight: bold;
}

.planned-action {
  font-size: 14px;
  padding: 6px;
}

.person-details {
  font-size: 15px;
}
</style>