<template>
  <div class="container">
    <div class="row startpage-item-collection">
      <div class="col-xs-12 text-col startpage-head">
        <div class="start-about-text">
          <h1>Nyblivna villaägare har drömmar, kan ni hjälpa till?</h1>
          <p>Husguiden är ett helhetskoncept för företag som vill nå personer som antingen köpt ett hus, en tomt eller ansökt om bygglov – och behöver anlita proffshjälp för sina bygg- och renoveringsprojekt.</p>
          <p><b>Kontakta oss så hjälper vi dig!</b></p>
          <button @click="$router.push('/kontakt')" class="call-to-action">Kontakta oss</button>
        </div>
      </div>

      <div class="col-md-6 startpage-tail">
        <div class="folder-container">
          <img class="folder-example-image" src="../../../assets/images/folder_startsida.png">

          <div class="browse-folder"
               style="margin-bottom:30px;">
            <strong style="font-size:16px;">Exempel</strong>
            <br>
            <a class="browse-folder--example-item"
               href="/flipbook?q=alvesta">
              <span>Alvesta</span>
            </a>
            <a class="browse-folder--example-item"
               href="/flipbook?q=ekero">
              <span>Ekerö</span>
            </a>
            <a class="browse-folder--example-item"
               href="/flipbook?q=katrineholm">
              <span>Katrineholm</span>
            </a>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'home',
    components: {},
    props: {},
  };
</script>

<style scoped>
  .start-about-text {
  }

  .market-text-img {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .folder-container {
    width: 100%;
    text-align: center;
  }

  .folder-example-image {
    width: 400px;
  }

  @media only screen and (max-width: 992px) {
    .folder-example-image {
      width: 100%;
    }
  }

  button.call-to-action {
    padding: 8px 16px;
    color: #19773D;
    border: 2px solid #19773D;
    background-color: #fff;
    font-weight: 600;
    border-radius: 4px;
    outline: none;
  }

  button.call-to-action:focus {
    border-color: #19773D;
  }

  button.call-to-action:active {
    background-color: #19773D;
    color: #fff;
  }

  .browse-folder {
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    color: #272525;
  }

  .browse-folder--example-item {
    display: inline-block;
    font-size: 16px;
    margin-right: 8px;
  }

  .browse-folder #browse-text {
    color: inherit;
  }

  .browse-folder #browse-icon {
    font-size: 30px;
    top: 5px;
    cursor: pointer;
  }

  img.folder-img {
    display: inline-block;
    width: calc((100% - 8px) / 5);
    padding-right: 8px;
  }

  .startpage-item-collection {
    display: flex;
    flex-direction: column;
  }

  .startpage-head {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 992px) {
    .people-left-col {
      display: block;
    }

    .about-text,
    .info-text {
      text-align: left;
      margin-bottom: 10px;
    }

    .market-text-img {
      margin-top: 80px;
    }

    .folder-container {
      margin-top: 90px;
    }

    .browse-folder {
      z-index: 999999;
      font-size: 24px;
      font-weight: bold;
      color: #272525;
    }

      .browse-folder #browse-text {
        color: inherit;
      }

      .browse-folder #browse-icon {
        font-size: 30px;
        margin-left: 5px;
      }

    .content-left {
      display: inline-block;
      margin-left: 10%;
    }

      .content-left .row {
        margin-right: 0;
      }

    .startpage-item-collection {
      display: flex;
      flex-direction: row;
    }

    .startpage-head {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .startpage-tail {
      flex: 0 0 calc((100% / 3) * 2);
      max-width: calc((100% / 3) * 2);
    }
  }

  @media (max-width: 992px) {
    .startpage-head {
      flex: 0;
      max-width: none;
    }

    .startpage-tail {
      flex: none;
      max-width: none;
    }

    .market-text-img {
      max-width: 90vw;
    }

    .folder-container {
      padding: 16px;
    }

    .startpage-item-collection {
      align-items: center;
    }

    .start-about-text {
      margin: 5%;
    }
  }

  @media (min-width: 1200px) {
    .market-text-img {
      margin-top: 110px;
    }

    .folder-container {
      margin-top: 110px;
    }
  }
</style>
