export default class ProductionDetailService {
  dataAccess;

  url;

  constructor(dataAccess) {
    this.dataAccess = dataAccess;
    this.url = `${this.dataAccess.API}ProductionDetail`;
  }

  getProductionDetail(prodHistId) {
    return this.dataAccess.getResponse(`${this.url}/${prodHistId}`);
  }

  getProductionDetailByEditionId(editionId, prodHistId) {
    return this.dataAccess.getResponse(`${this.url}/${editionId}/${prodHistId}`);
  }

  getFollowUp(id) {
    return this.dataAccess.getResponse(`${this.url}/Plans/${id}`);
  }

  downloadProductionDetail(prodHistId, editionId) {
    let url = `${this.url}/export/${prodHistId}`;

    if (editionId !== null) {
      url += `/${editionId}`
    }
    
    return this.dataAccess.getResponse(url);
  }
}
