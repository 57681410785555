import DataAccessHelperService from '../data-access.service';
import TokenService from '../auth/token.service';
import CookieService from './cookie.service';
import AuthService from '../auth/auth.service';
import UserService from '../auth/user/user.service';
import ProductionHistoryService from '../production-history.service';
import ProductionDetailService from '../production-detail.service';
import AnsweredService from '../answered.service';
import router from './router';

const cookieService = new CookieService();
const tokenService = new TokenService(cookieService);
const dataAccessHelper = new DataAccessHelperService(router, tokenService);
const userService = new UserService(dataAccessHelper);
const authService = new AuthService(dataAccessHelper, userService, tokenService);
const productionHistoryService = new ProductionHistoryService(dataAccessHelper);
const productionDetailService = new ProductionDetailService(dataAccessHelper);
const answeredService = new AnsweredService(dataAccessHelper);

export {
  cookieService,
  tokenService,
  dataAccessHelper as dataAccessHelperService,
  authService,
  userService,
  productionHistoryService,
  productionDetailService,
  answeredService,
};
