var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container route-container"},[_c('div',{staticClass:"head-container control-section"},[_c('div',{staticClass:"head-container-part"},[(_vm.user)?_c('select-with-description',{attrs:{"name":"profileCategory","description":_vm.$t('Choose profile:'),"items":Object.keys(_vm.profileConfigs).filter(item => !_vm.profileConfigs[item].hidden),"inline-description":true,"selectKey":item => item,"selectLabel":item => _vm.profileConfigs[item].name,"onChange":_vm.handleRequestChangeProfileConfig,"activeItem":_vm.activeProfileConfigId}}):_vm._e()],1)]),_c('tab-view',{attrs:{"pages":[
    { label: _vm.$t('All'), name: 'all', component: _vm.AllTabComponent },
    { label: _vm.$t('Answered'), name: 'answered', component: _vm.AnsweredTabComponent }
  ],"tabProps":{
      user: _vm.user,
      activeProfileConfigId: _vm.activeProfileConfigId,
      sharedMethods: _vm.sharedMethods,
      isLoading: _vm.isLoading,
      showModal: _vm.showModal,
      profileConfigs: _vm.profileConfigs,
      updatePageSettings: _vm.updatePageSettings,
      dispatches: _vm.dispatches,
      totalDispatchCount: _vm.totalDispatchCount,
      handleRequestChangePage: _vm.handleRequestChangePage,
      handleRequestChangePageSize: _vm.handleRequestChangePageSize,
      pageSize: _vm.pageSize,
      pageIndex: _vm.pageIndex,
      handleRequestOpenDialog: _vm.handleRequestOpenDialog,
      calculatedPageCount: _vm.calculatedPageCount,
    },"initialPage":"all"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }