export default class UserService {
  usersUrl;

  dataAccess;

  constructor(dataAccess) {
    this.dataAccess = dataAccess;
    this.usersUrl = `${this.dataAccess.API}users`;
  }

  getUser(id) {
    return this.dataAccess.getResponse(`${this.usersUrl}/${id}`).then(response => response.data);
  }

  updateUser() {
    // return this.dataAccess.post(`${this.usersUrl}/`, user);
    throw new Error('Not implemented in API');
  }
}
