<template>
  <div>
    <expandable-table
      v-if="answered.length > 0 && !isLoading"
      :column-names="[
        $t('Name'),
        $t('Municiple'),
        $t('Description'),
        $t('Receipt Date'),
        $t('Planned investment'),
      ]"
      :sorting-functions="[nameSort, municipleSort, descriptionSort, updateDateSort]"
      :values="answered"
      :get-key="value => value.cooId"
      :table-item-component="SearchResultItem"
      :expansion-component="SearchResultItemExpansion"
      :table-item-component-props="{onRequestOpenDialog: handleRequestOpenDialog, isAnswer: true}"
      :expansion-component-props="{onRequestOpenDialog: handleRequestOpenDialog}"
    />
    <div
      v-if="answered.length < 1 && !isLoading"
      class="status-message"
    >
      {{ $t('There are no answers from this area') }}
    </div>
  </div>
</template>

<script>
import sortingFunctions from '@/shared/sorting';
import ExpandableTable from '@/components/ExpandableTable';
import SearchResultItem from '@/components/pages/ProductionHistory/tabs/all/SearchResultItem';
import SearchResultItemExpansion from '@/components/pages/ProductionHistory/tabs/all/SearchResultItemExpansion';
import { answeredService } from '@/shared/services';
import LazySpinner from '@/shared/LazySpinner';

export default {
  components: {
    ExpandableTable,
  },
  props: ['activeProfileConfigId', 'profileConfigs', 'handleRequestOpenDialog'],
  data() {
    return {
      answered: [],
      isLoading: false,
      loadedProfileConfigId: null,
      SearchResultItem,
      SearchResultItemExpansion,
    };
  },
  watch: {
    activeProfileConfigId() {
      if (this.tabIsActive) {
        this.loadAnsweredIfNeeded();
      }
    },
  },
  methods: {
    loadAnswered() {
      this.isLoading = true;
      const setSpinnerNotNeeded = LazySpinner.show();
      const profileConfig = this.profileConfigs[this.activeProfileConfigId];

      if (profileConfig.editionId !== null) {
        answeredService
          .getAnsweredByEditionId(
            profileConfig.editionId,
            profileConfig.profileId,
          )
          .then(({ data: response }) => {
            const { data: answered } = response;

            this.loadedProfileConfigId = this.activeProfileConfigId;
            this.answered = answered;
          })
          .finally(() => {
            setSpinnerNotNeeded();
            this.isLoading = false;
          });
      } else {
        answeredService
          .getAnsweredByRInfoId(
            profileConfig.profileId,
          )
          .then(({ data: response }) => {
            const { data: answered } = response;

            this.loadedProfileConfigId = this.activeProfileConfigId;
            this.answered = answered;
          })
          .finally(() => {
            setSpinnerNotNeeded();
            this.isLoading = false;
          });
      }
    },
    loadAnsweredIfNeeded() {
      if (this.loadedProfileConfigId !== this.activeProfileConfigId) {
        this.loadAnswered();
      }
    },
    didBecomeActive() {
      this.tabIsActive = true;
      this.loadAnsweredIfNeeded();
    },
    didBecomeInactive() {
      this.tabIsActive = false;
    },
    ...sortingFunctions,
  },
};
</script>

<style lang="scss" scoped>
</style>
