<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-8">
        <div class="info-text-title">
          Husguiden delas ut till privatpersoner som precis köpt en befintlig
          villa eller fritidshus eller som ansökt om bygglov – och därmed i hög
          grad är redo att investera i sina fastigheter.
        </div>

        <div class="info-text">
          Bland Husguidens mottagare finns ett stort intresse av att komma i
          kontakt med hantverkare, butiker och tjänsteföretag samt att ta del av
          olika erbjudanden som passar just deras bygg- och inköpsplaner –
          oavsett om de planerar att bygga nytt, bygga om eller bygga till.
        </div>
        <div class="info-text">
          Vi gör ett direktadresserat utskick till denna målgrupp på deras
          befintliga bostadsadress innan flytten sker till det nya huset. Som
          annonsör i Husguiden kommer du att synas med en företagspresentation i
          vår A4-folder – och du kommer (garanterat) att nå rätt målgrupp med
          rätt budskap vid rätt tidpunkt
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@media (min-width: 0px) {
  .footer {
    bottom: 10px;
  }
}

/** About **/

.people-right-img {
  width: 100%;
  max-width: 550px;
  height: auto;
}

.arrow-image {
  width: 300px;
}

.info-text-title {
  color: #273548;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
}
.info-text {
  margin-bottom: 10px;

  text-align: left;
}

.arrow-image {
  margin-left: 33%;
}

@media (min-width: 0px) {
  .arrow-image {
    margin-bottom: 10%;
  }

  .about-container .content-left {
    margin-top: 40px;
  }

  .dot::before {
    content: '';
  }
}

@media only screen and (min-width: 768px) {
  .arrow-image {
    margin-left: 33%;
  }
}

@media (min-width: 992px) {
  .people-right-col {
    display: block;
    float: right;
  }

  .about-container .content-left {
    margin-top: 40px;
    width: 33%;
  }

  .dot::before {
    content: '\25CF';
    margin-right: 8px;
  }
}
</style>