<template>
  <div class="sales-letter-container container">
    <div class="row">
      <div class="col-xs-12 col-md-3" v-for="reference in namedReferences" v-bind:key="reference.name">
        <div class="thumbnail">
          <img :src="require(`../../../assets/images/sales_letters/${reference.img}`)"
               class="thumbnail-img"
               width="220"
               height="311" />
        </div>
        <div class="caption">
          <h3 class="text-center">{{ reference.name }}</h3>
          <p v-html="reference.text" class="text-center"></p>
        </div>
      </div>
    </div>
    <div class="row">
      <h3>Andra ord om HUSGUIDENS fördelar och möjligheter</h3>
    </div>
      <div class="row">
        <div class="col-xs-12 col-md-4" v-for="reference in anonymousReferences" v-bind:key="reference.name">
          <div class="caption">
            <h3 class="text-center">{{ reference.type }}</h3>
            <p v-html="reference.text" class="text-center"></p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      references: [
        {
          type: 'Byggvaruhandel',
          name: 'XL-Bygg Vårgårda',
          img: '3537869_XL_BYGG_Vårgårda-1.jpg',
          img_lg: '3537869_XL_BYGG_Vårgårda-1.jpg',
          text:
            '"Varje vecka kontaktar vi husägarna på uppföljningslistorna med en inbjudan till att besöka vår bygghandel och samtalar om deras kommande byggprojekt. Det ger resultat och vi får regelbundet in nya kunder på detta sätt via Husguiden."',
        },
        {
          type: 'Kommunala tjänster',
          name: 'Sundsvalls kommun',
          img: '150611_Sundsvalls_kommun-1.jpg',
          img_lg: '150611_Sundsvalls_kommun-1.jpg',
          text:
            '"Energirådgivning är ett högaktuellt ämne för dessa mottagare. Vi besvarar mottagarna via uppföljningslistorna och får möjlighet att ge information om åtgärder som ger ett långsiktigt mervärde! Det kan vara värdefullt för mottagarna att få med rätt tänk om uppvärmning, ventilation, fönster, isolering, solceller, laddbox till elbil, elförbrukning och aktuella bidrag."',
        },
        {
          type: 'FALKENBERG',
          name: 'Falkenberg Energi',
          img: '118970_Falkenberg_Energi_5_Tuffast-1.jpg',
          img_lg: '118970_Falkenberg_Energi_5_Tuffast-1.jpg',
          text:
            '"Vi vill vara det självklara elbolaget för dig som bor i Falkenberg och Husguiden hjälper oss att sprida att vi finns. Vi ser även ett värde i att synas när folk flyttar in till Falkenberg."',
        },
        {
          type: 'VVS-ENTREPRENÖR',
          name: 'Sydpumpen AB',
          img: 'sydpumpen.png',
          img_lg: 'sydpumpen_lg.png',
          text:
            '"Relevanta adresser till husägare är aldrig fel! Kontakten är alltid värdefull – både kort och långsiktigt! Det gäller att ta tag i uppföljningslistorna – där ligger det guldkorn och väntar!" <br> <strong>Lars Karlsson Sydpumpen AB</strong>',
        },
        {
          type: 'MARKENTREPRENÖR',
          name: 'Reaxcer Åre',
          img: 'Reaxcer_Berg-1.jpg',
          img_lg: 'reaxcer.png',
          text:
            '"Vi tycker Husguiden är ett kostnadseffektivt sätt att nå vår målgrupp. Vi når dom som är på väg att investera inom vårt geografiska verksamhetsområde"',
        },
        {
          type: 'BYGGVARUHANDEL',
          name: 'Optimera Lidköping',
          img: '20200211_Lidkoping.husguiden.feb-1.jpg',
          img_lg: 'optimera_lg.png',
          text:
            '"Vi tycker att uppföljningslistorna är bra att jobba vidare med och vi gör egna uppföljande utskick"',
        },
        {
          type: 'Plåtentreprenör och kaminförsäljning',
          name: 'Harmångers Plåt & Entreprenad AB',
          img: 'harmangers_plat.png',
          img_lg: 'harmangers_plat_lg.png',
          text: '"Husguiden är ett enkelt verktyg att nå ut till nya kunder."',
          isAnonymous: true,
        },
        {
          type: 'Kommunal renhållning och avfallshantering',
          name: 'Piteå Renhållning & Vatten AB Pireva',
          img: 'pireva.png',
          img_lg: 'pireva_lg.png',
          text:
            '"Vissa privatpersoner tror inte att det går att hyra en container hos oss. Vi ser genomslag på detta!"',
          isAnonymous: true,
        },
        {
          type: 'TRÄDGÅRDSANLÄGGARE',
          name: 'Sandbergs anläggningstjänst i Sjöbo',
          img: '701908_Sandberg-1.jpg',
          img_lg: 'sandberg_lg.png',
          text:
            '"Husguiden har lett till en hel del kontakter och uppdrag för oss genom åren"',
        },
        {
          type: 'FÄRGHANDEL',
          name: 'Färg 1:an i Köping',
          img: 'farg1an.png',
          img_lg: 'farg1an_lg.png',
          text:
            '"Vi förses hela tiden med nya kunder till butiken genom Husguiden utskick. Allt fungerar bra och så får vi ju uppföljning på det som skickats!"',
        },
        {
          type: 'Energiborrning och vvs-entreprenör',
          name: 'Bergvärme i Sollefteå AB',
          img: 'bergvarme_solleftea.png',
          img_lg: 'bergvarme_solleftea_lg.png',
          text:
            '"Rätt tidpunkt och vid rätt tillfälle! Husguiden är ett bra sätt att nå ut till nya kunder vid ett tillfälle då den potentiella kunden själv kan tänkas känna behov av våra tjänster. Dessutom har vi fått snabb respons på de ändringar vi velat göra i vårt informationsblad."',
          isAnonymous: true,
        },
        {
          type: 'BYGGARE',
          name: 'Edbergs Bygg',
          img: 'edbergs_bygg.png',
          img_lg: 'edbergs_bygg_lg.png',
          text:
            '"Det bästa är att nå dem utifrån som köper ett fritidshus. T.ex någon som bor i Stockholm och köper ett fritidshus här i Eskilstuna. Det kommer in jobb den vägen, så det är bra!"',
        },
        {
          type: 'Kaminförsäljning',
          name: 'Eldabutiken - Bohus Värme',
          img: 'eldabutiken.png',
          img_lg: 'eldabutiken_lg.png',
          text:
            '"Vi har känt igen flera personer på uppföljningslistorna som har kommit in i butiken. Upprepningseffekten är något vi tror på - och att personerna kommer att vara återkommande kunder. Vi har satsat på ett långsiktigt och hållbart budskap i HUSGUIDEN."',
          isAnonymous: true,
        },
        {
          type: 'Skogs och trädgrådstjänster',
          name: 'Börjes Skogs & Trädgårdstjänst AB',
          img: 'borjesskogs.jpg',
          img_lg: 'borjesskogs.jpg',
          text:
            '"Jag ser en möjlighet i HUSGUIDENS uppföljningslistor för de kan hjälpa till att skapa nya kontakter i grannskapet där jag redan har jobb på gång!<br><br>Jag kan komplettera med en annan flyer - flygblad när jag åker förbi."',
          isAnonymous: true,
        },
      ],
    };
  },
    computed: {
      namedReferences() {
        return this.references.filter(reference => !reference.isAnonymous)
      },
      anonymousReferences() {
        return this.references.filter(reference => reference.isAnonymous)
      }
    }
};
</script>

<style lang="scss" scoped>
</style>
